// @ts-nocheck
import React from 'react';
import { Card, Row, Col, Image, Spin, Divider, Table, Tag } from 'antd';

const ProductImage = (props: any) => {
  const { productData, isLoading } = props;
  const digitalFileInfo = productData.digital_library_files[0];

  return (
    <Card
      bordered={true}
      style={{ backgroundColor: 'transparent', height: '100%' }}
    >
      {isLoading ? (
        <Row>
          <Col span={24} style={{ paddingTop: '50%' }}>
            <Spin size={'large'} tip={'Loading Cost Analysis...'} />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <Image
              preview={false}
              src={
                digitalFileInfo
                  ? digitalFileInfo.image_url
                  : 'https://www.threekit.com/hubfs/TK20/Industries/ind_modular.svg'
              }
              style={{ width: '50%' }}
            />
            <div>
              <span>x: {digitalFileInfo ? digitalFileInfo.dim_x : 0} mm</span>
              <Divider type={'vertical'} />
              <span>y: {digitalFileInfo ? digitalFileInfo.dim_y : 0} mm</span>
              <Divider type={'vertical'} />
              <span>z: {digitalFileInfo ? digitalFileInfo.dim_z : 0} mm</span>
            </div>

            <div>
              <span>Area: {digitalFileInfo ? digitalFileInfo.area : 0}</span>
              <Divider type={'vertical'} />
              <span>Vol: {digitalFileInfo ? digitalFileInfo.volume : 0}</span>
            </div>
          </Col>

          <Divider />
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'Material Class',
                  dataIndex: 'Material Class',
                  key: 'Material Class',
                },
                {
                  title: 'Total Price(USD)',
                  dataIndex: 'Total Price',
                  key: 'Total Price',
                  render: (text: any, record: any) => (
                    <span>
                      <Tag>{text}</Tag>
                    </span>
                  ),
                },
              ]}
              dataSource={digitalFileInfo ? digitalFileInfo.cost_analysis : []}
              pagination={false}
              size="small"
              style={{ backgroundColor: 'transparent' }}
            />
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default ProductImage;
