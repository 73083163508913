import {Card, Col, Descriptions, Divider, Image, Row, Tag, Typography} from 'antd';
import React from 'react';
import OrderProduct from '../../../OrderProduct';
import {BorderOutlined, DollarOutlined, ShopOutlined, ToolOutlined, UserOutlined} from "@ant-design/icons";

const ProductDescription = (props: any) => {
  const { product } = props;
  const fileData = product.digital_library_files;

  return (
    <Card
      type={'inner'}
      style={{ backgroundColor: '#1f1f1f' }}
      bordered={false}
      size={'small'}
    >
      <Row>
        <Col span={16} style={{ textAlign: 'center' }}>
          <Card style={{ backgroundColor: 'transparent' }}>
            <Image
              height={250}
              preview={false}
              src={
                //check if the image is available
                fileData[0]
                  ? //if available, return the image
                    fileData[0].image_url
                  : //if not available, return the default image
                    'https://www.threekit.com/hubfs/TK20/Industries/ind_modular.svg'
              }
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ backgroundColor: 'transparent', height: '100%' }}>
            <OrderProduct product={product} />
          </Card>
        </Col>
        <Col span={24}>
 <Card style={{minHeight: '45vh', backgroundColor: '#1f1f1f'}}   title={<Typography.Text>Product Summary</Typography.Text>}>
     <Row>
         <Col span={24}>
                    <Descriptions
                  layout={'vertical'}
                  bordered
                  column={4}
                >
                  <Descriptions.Item label="Owner">
                      <Tag icon={<UserOutlined/>}>{product.company.name}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Industry">
                      <Tag icon={<ShopOutlined/>}>{product.company.industry}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Category">
                      <Tag icon={<BorderOutlined/>}>{product.category.name}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Notes">
                      <Tag>{product.product_notes}</Tag>
                  </Descriptions.Item>
             <Descriptions.Item label="Manufacturer">
                 <Tag icon={<ToolOutlined/>}>{product.manufacturer}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Technology">
                      <Tag icon={<BorderOutlined/>}>{product.digitization.manufacturing_technology}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Material">
                      <Tag icon={<BorderOutlined/>}>{product.digitization.material}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Manufacturing Price (USD)">
                      <Tag icon={<DollarOutlined/>}>{product.manufacturing_price_usd}</Tag>
                  </Descriptions.Item>

                  <Descriptions.Item label="Certification/Testing Price (USD)">
                      <Tag icon={<DollarOutlined/>}>{product.testing_price_usd}</Tag>
                  </Descriptions.Item>

                  <Descriptions.Item label="Shipping Price (USD)">
                      <Tag icon={<DollarOutlined/>}>{product.shipping_price_usd}</Tag>
                  </Descriptions.Item>
                          <Descriptions.Item label="Price (USD)">
                      <Tag icon={<DollarOutlined/>}>{product.ivaldi_price_usd}</Tag>
                  </Descriptions.Item>
                         </Descriptions>

              </Col>
     </Row>
 </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default ProductDescription;
