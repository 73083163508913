// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  Layout,
  Menu,
  Card,
  Space,
  PageHeader,
  Typography,
  Row,
  Col,
  Statistic,
} from 'antd';
import {Column} from '@ant-design/plots';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { getAnalysisResult } from './services';




const AnalysisResult = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state;


  const chartConfig = {
    data: item.results.chartData,
    autoFit: true,
    xField: 'category',
    yField: 'found',
    label: {
      position: 'middle',
      style: {
        fontSize: 18,
        fontWeight: 'bold',
        fill: 'white',
      },
    },
  };

  return (
    <>
      <PageHeader
        backIcon={<ArrowLeftOutlined style={{ color: '#cda83b' }} />}
        onBack={() => navigate('/Analysis')}
        title={'Analysis Result'}
        subTitle={
          <Typography.Text type={'secondary'} italic={true}>
            {item.id}
          </Typography.Text>
        }
      />
      <Card style={{ height: '100%', backgroundColor: '#1f1f1f' }}>
        <Row gutter={[16,16]}>
          <Col span={8}>
            <Card style={{ background: 'transparent' }}>
              <Statistic
                title={'Total Spend'}
                value={item.results.categoriesTotalSpend}
                prefix={'$'}
                precision={2}
              />
            </Card>
          </Col>

          <Col span={8}>
            <Card style={{ background: 'transparent' }}>
              <Statistic
                title={'Total Products'}
                value={item.results.categoriesTotalProducts}
              />
            </Card>
          </Col>

          <Col span={8}>
            <Card style={{ background: 'transparent' }}>
              <Statistic
                title={'Total Qty Ordered'}
                value={item.results.categoriesTotalQty}
              />
            </Card>
          </Col>

          <Col span={8}>
            <a href={item.report} target={'_blank'} rel={'noopener noreferrer'}>
                <Document file={item.report}>
                  <Page scale={0.5} pageNumber={1}/>
                </Document>
            </a>


          </Col>
          <Col span={16}>
            <Card title={'Categories Identified'} style={{background: 'transparent', height: '100%'}}>
              <Column {...chartConfig}/>
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default AnalysisResult;
