// @ts-nocheck

import React from 'react';
import {Col, Row, Card, Table, Select, Tag} from 'antd';

const DataInfo = (props: any) => {
  const { info, handleChange, handleOnDeselect, options } = props;

  const columns = [
    {
      title: 'Column',
      dataIndex: 'Column',
      key: 'Column',
    },
    {
      title: 'Data Type',
      dataIndex: 'Data type',
      key: 'Data type',
    },
    {
      title: 'Null count',
      dataIndex: 'Null count',
      key: 'Null count',
    },
    {
      title: 'Mapped Columns',
      dataIndex: 'select',
      key: 'select',
      render: (text: any, record: any) => {
        return (
          <Select
            size={'small'}
            style={{ width: 125 }}
            allowClear
            onDeselect={handleOnDeselect}
            onChange={(value) => handleChange(value, record)}
          >
            {options.map((option) => (
              <Select.Option key={option} value={option}>
                <Tag color={'green'} style={{border: 'none'}}>{option}</Tag>
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
  ];

  return (
    <Card
      style={{ background: 'transparent' }}
      title={'Select columns for analysis'}
    >
      <Row>
        <Col span={24}>
          <Table
            pagination={false}
            size={'small'}
            dataSource={info}
            columns={columns}
            scroll={{ x: 100, y: 300}}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default DataInfo;
