// @ts-nocheck
import {Auth} from "aws-amplify";

const axios = require('axios');
const axiosRetry = require('axios-retry');
axiosRetry(axios, {retries: 3});

export const getAdminAnalysis = async () => {
    const accessToken = localStorage.getItem('accessToken');
    const config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/admin/AdminAnalysis/`,
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },

    }
    try {
        const response = await axios(config);
        return response.data;
    } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
            Auth.signOut();
        }
    }

}

export const updateAnalysis = async (analysisId, data) => {
    const accessToken = localStorage.getItem('accessToken');
    const config = {
        method: 'patch',
        url: `${process.env.REACT_APP_API_URL}/api/admin/AdminAnalysis/${analysisId}/`,
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        data

    }
    try {
        const response = await axios(config);
        return response.data;
    } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
            Auth.signOut();
        }
    }

}

export const screen = async (analysisId, columns) => {
    const accessToken = localStorage.getItem('accessToken');
    const config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/admin/AdminAnalysis/${analysisId}/screen/`,
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        params:{cols: columns}

    }
        const response = await axios(config);
        return response.data;
}