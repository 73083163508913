import React from 'react';
import {Checkbox, Row, Col, Card, Typography, Divider} from 'antd';




const ClassFilter = (props:any) => {
    const {amClasses, onClassChange} = props;

    return (
        <>
            <div>
                <Typography.Title level={5}> Filter by Class</Typography.Title>
            </div>
            <Divider/>
            <Checkbox.Group style={{width: '100%'}} onChange={onClassChange}>
                <Row>
                    {amClasses.map((category: any) => (
                        <Col key={category} span={24}>
                            <Checkbox value={category}>{category}</Checkbox>
                        </Col>
                    ))}
                </Row>
            </Checkbox.Group>

        </>
    )

}

export default ClassFilter;