// @ts-nocheck
import React from 'react';
import {Card, Col, Image, List, Row, Tag, Typography} from 'antd';
import { Link } from 'react-router-dom';

const ProductCard = (props) => {
    const {products} = props
  return (
    <List

      grid={{ gutter: [36, 36], column:3, xs: 1, sm: 1, md: 2, lg:3 }}
      dataSource={products}
      pagination={{
        pageSize: 9,
        showTotal: (total) => `${total} items`,
        style: { paddingTop: '2rem' },
        size: 'small',
      }}
      renderItem={(product) => (
        <List.Item>
          <Link
            to={`/DigitalLibrary/detail/${product.id}`}
            state={{ productData: product }}
          >
            <Card
              style={{ backgroundColor: '#1f1f1f', borderRadius: '0.25rem' }}
              size="small"
              title={<Typography.Text strong>{product.part_name}</Typography.Text>}
              hoverable={true}
            >
              <Row gutter={[12, 12]}>
                <Col span={12} style={{ textAlign: 'center' }}>
                  <Image
                    preview={false}
                    height={100}
                    src={
                      product.digital_library_files[0]
                        ? product.digital_library_files[0].image_url
                        : 'https://www.threekit.com/hubfs/TK20/Industries/ind_modular.svg'
                    }
                  />
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Card.Meta
                    title={<Typography.Text>{product.company_part_id}</Typography.Text>}
                    description={
                      <Row>
                        <Col span={24}>{product.category.name}</Col>
                        <Col span={24}>{product.digitization.am_class}</Col>
                        <Col span={24}>${product.ivaldi_price_usd} </Col>
                      </Row>
                    }
                  />
                </Col>
              </Row>
            </Card>
          </Link>
        </List.Item>
      )}
    />
  );
};

export default ProductCard;
