// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  message,
  Modal,
  PageHeader,
  Row,
  Tabs,
  Typography,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ProductForm from '../ProductForm';
import DigitizationAdmin from '../DigitizationAdmin';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import ProductImage from '../ProductImage';
import ProductHistoryAdmin from '../ProductHistoryAdmin';
import { Auth } from 'aws-amplify';
import Manufacturing from "../Manufacturing";

axiosRetry(axios, {retries: 5});

const ProductDetailAdmin = () => {
  const location = useLocation();
  const product = location.state;

  const [visible, setVisible] = useState(false);
  const [productData, setProductData] = useState(product);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');

    const config = {
      url: `${process.env.REACT_APP_API_URL}/api/admin/DigitalLibraryAdmin/${productData.id}/`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios(config)
      .then((response) => {
        const newData = response.data;
        setProductData(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const digitization = productData.digitization;
  const initialValues = {
    ...product,
    ...digitization,
    company: productData.company.id,
    category: productData.category.id,
  };
  const navigate = useNavigate();

  const [isComplete, setIsComplete] = React.useState(productData.is_complete);
  const [productHistory, setProductHistory] = React.useState([]);
  const [productHistoryLoading, setProductHistoryLoading] = useState(false)
  const [tabActiveKey, setTabActiveKey] = useState('1')

  const getFileName = function (str) {
    return str.split('\\').pop().split('/').pop();
  };
  const onSaveForm = (values: any) => {
    console.log(values);
    const accessToken = localStorage.getItem('accessToken');
    const config = {
      url: `${process.env.REACT_APP_API_URL}/api/admin/DigitalLibraryAdmin/${productData.id}/`,
      method: 'patch',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: values,
    };
    axios(config)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          message.success('Updated successfully');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fileUploadProps = {
    multiple: false,
    name: 'file',
    method: 'post',
    action: `${process.env.REACT_APP_API_URL}/api/admin/DigitalLibraryFiles/`,
    headers: {
      authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    data: {
      digital_library_product: productData.id,
    },
    onChange(info: any) {
      if (info.file.status === 'done') {
        setTabActiveKey('1')
        setIsLoading(true);
        message.success(`${info.file.name} file uploaded successfully`);
        const fileId = info.file.response.id;

        const analysisConfig = {
          method: 'get',
          url: `${process.env.REACT_APP_API_URL}/api/admin/DigitalLibraryFiles/${fileId}/cost_analysis/`,
          headers: {
            authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        };
        axios(analysisConfig)
          .then((res) => {
            setProductData({
              ...productData,
              digital_library_files: [res.data.fileInfo],
            });

            setIsLoading(false);

          })
          .catch((err) => {
            if (err.response.status === 401) {
              Auth.signOut();
            }
            console.log(err);
          });
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove(file: any) {
      const config = {
        method: 'delete',
        url: `${process.env.REACT_APP_API_URL}/api/admin/DigitalLibraryFiles/${productData.digital_library_files[0].id}/`,
        headers: {
          authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      };
      axios(config)
        .then(() => {
          setProductData({
            ...productData,
            digital_library_files: [],
          });
          message.success(`${file.name} file deleted successfully`);
        })
        .catch(() => {
          message.error(`${file.name} file delete failed.`);
        });
    },
    defaultFileList: productData.digital_library_files.map((file: any) => {
      return {
        uid: file.id,
        name: getFileName(file.file),
      };
    }),
  };
  const confirmProductDelete = (productId) => {
    const accessToken = localStorage.getItem('accessToken');
    const config = {
      url: `${process.env.REACT_APP_API_URL}/api/admin/DigitalLibraryAdmin/${productId}/`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios(config)
      .then((res) => {
        console.log(res.data);
        message.success('Project Deleted');
        hideModal();
        navigate('/ProductAdmin');
      })
      .catch((err) => {
        if (err.response.status === 401) {
          Auth.signOut();
        }
        console.log(err);
      });
  };
  const onApproveProduct = (productId) => {
    const accessToken = localStorage.getItem('accessToken');
    const config = {
      url: `${process.env.REACT_APP_API_URL}/api/admin/DigitalLibraryAdmin/${productId}/approve/`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        isComplete: !isComplete,
      },
    };
    axios(config)
      .then((res) => {
        console.log(res.data);
        setIsComplete(!isComplete);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          Auth.signOut();
        }
        console.log(err);
      });
  };
  const getProductHistory = () => {
    setProductHistoryLoading(true)
    const accessToken = localStorage.getItem('accessToken');
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/api/admin/DigitalLibraryAdmin/${productData.id}/get_history/`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios(config)
      .then((res: any) => {
        setProductHistory(res.data.history);
        setProductHistoryLoading(false)
      })
      .catch((err: any) => {
        if (err.response.status === 401) {
          Auth.signOut();
        }
        console.log(err);
      });
  };

  return (
    <>
      <PageHeader
        backIcon={<ArrowLeftOutlined style={{ color: '#cda83b' }} />}
        onBack={() => navigate('/ProductAdmin')}
        title={productData.part_name}
        subTitle={
          <Typography.Text italic={true}>{productData.id}</Typography.Text>
        }
      />

      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 24 }}
        initialValues={initialValues}
        labelAlign={'left'}
        onFinish={onSaveForm}
      >
        <Card style={{ minHeight: '85vh', backgroundColor: '#1f1f1f' }}>
          <Tabs
            activeKey={tabActiveKey}
            tabBarExtraContent={
              <Form.Item>
                <Button onClick={showModal}>Delete</Button>
                <Divider type="vertical" />
                {isComplete ? (
                  <Button onClick={() => onApproveProduct(productData.id)}>
                    Unpublish
                  </Button>
                ) : (
                  <Button onClick={() => onApproveProduct(productData.id)}>
                    Publish
                  </Button>
                )}
                <Divider type="vertical" />
                <Button htmlType="submit" type={'primary'}>
                  Save
                </Button>
              </Form.Item>
            }
            onChange={(activeKey) => {
              if (activeKey === '4') {
                getProductHistory();
              }

              setTabActiveKey(activeKey)

            }}
          >
            <Tabs.TabPane key={'1'} tab={'Product'}>
              <Row gutter={[0, 24]}>
                <Col span={8} style={{ textAlign: 'center' }}>
                  <ProductImage
                    productData={productData}
                    isLoading={isLoading}
                  />
                </Col>
                <Col span={16}>
                  <ProductForm
                    isComplete={isComplete}
                    productData={productData}
                  />
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane key={2} tab={'Digitization'}>
              <Row>
                <Col span={24}>
                  <DigitizationAdmin
                    fileUploadProps={fileUploadProps}
                    product={productData}
                    digitization={digitization}
                  />
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane key={3} tab={'Manufacturing'}>
              <Manufacturing productData={productData}/>
            </Tabs.TabPane>

            <Tabs.TabPane key={4} tab={'History'}>
              <Row>
                <Col span={24}>
                  <ProductHistoryAdmin productHistory={productHistory} isLoading={productHistoryLoading} />
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Card>

        <Modal
          title={`Delete ${productData.part_name}`}
          visible={visible}
          onOk={() => confirmProductDelete(productData.id)}
          onCancel={hideModal}
          okText="Delete"
          cancelText="Cancel"
        >
          <p>Are you sure you want to delete this project</p>
        </Modal>
      </Form>
    </>
  );
};

export default ProductDetailAdmin;
