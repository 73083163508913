// @ts-nocheck
import React from 'react';
import { Column } from '@ant-design/plots';
import { Card, Row, Col, Table, Statistic } from 'antd';
import './style.less';

const CostTable = (props: any) => {
  const { leadTime, unitPriceUsd } = props;
  let data;
  if (props.data) {
    data = props.data;
  } else {
    data = [];
  }

  const config = {
    data,
    xField: 'Material Class',
    yField: 'Total Price',
    label: {
      position: 'middle',
      style: {
        fontSize: 18,
        fontWeight: 'bold',
        fill: 'white',
      },
    },
  };

  return (
    <Card style={{ backgroundColor: 'transparent', height: '100%' }}>
      <Row gutter={[10, 24]}>
        <Col span={6}>
          <Card style={{ backgroundColor: 'transparent', height: '100%' }}>
            <div>
              <Statistic title="Current price(USD)" value={unitPriceUsd} />
            </div>
            <div style={{ paddingTop: '60px' }}>
              <Statistic title="Current lead time(days)" value={leadTime} />
            </div>
          </Card>
        </Col>
        <Col span={18}>
          <Table
            columns={[
              {
                title: 'Approved Materials',
                dataIndex: 'Material Class',
                key: 'Material Class',
              },
              {
                title: 'Total Price(USD)',
                dataIndex: 'Total Price',
                key: 'Total Price',
              },
              {
                title: 'Avg Production time(days)',
                dataIndex: 'productionTimeFast',
                key: 'productionTimeFast',
              },
              {
                title: 'Avg Delivery time(days)',
                dataIndex: 'deliveryTimeFastest',
                key: 'deliveryTimeFastest',
              },
            ]}
            dataSource={data}
            pagination={false}
            size="small"
            style={{ backgroundColor: 'transparent' }}
          />
        </Col>
        <Col span={24}>
          <Column {...config} />
        </Col>
      </Row>
    </Card>
  );
};

export default CostTable;
