import {BarChartOutlined,MailOutlined, AppstoreOutlined } from "@ant-design/icons";
import Analysis from "./components/Analysis";
import Orders from "./components/Orders";
import DigitalLibrary from "./components/DigitalLibrary";


const routes = [
  {
    name: 'Digital Library',
    link: '/DigitalLibrary',
    icon: <AppstoreOutlined/>,
    element: <DigitalLibrary/>
  },
  {
    name: 'Orders',
    link: '/Orders',
    icon: <MailOutlined/>,
    element: <Orders/>
  },
  {
    name: 'Analysis',
    link: '/Analysis',
    icon: <BarChartOutlined/>,
    element: <Analysis/>
  }
];

export default routes
