// @ts-nocheck
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Upload,
  PageHeader,
  Typography,
    message,
} from 'antd';
import {
  InboxOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import AnalysisList from './components/AnalysisList';
import { getAnalysis } from './services';
import {companyAnalysis} from "../../store";
import {useAtom} from "jotai";
const axios = require('axios');
const axiosRetry = require('axios-retry');

axiosRetry(axios, { retries: 3 });

const Analysis: React.FC = () => {
  const [analysisRecords, setAnalysisRecords] = useAtom(companyAnalysis);

  useEffect(() => {
    getAnalysis().then((data) => {
      setAnalysisRecords(data);
    });
  }, []);

  const uploadProps = {
    name: 'uploaded_data_file',
    action: `${process.env.REACT_APP_API_URL}/api/analysis/`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    data: {
      company: JSON.parse(localStorage.getItem('companyId')),
      user: JSON.parse(localStorage.getItem('userID')),
    },
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        setAnalysisRecords(analysisRecords => [...analysisRecords, info.file.response]);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      <PageHeader
        title="Analysis"
        subTitle={
          <Typography.Text italic={true} type="secondary">
            {' '}
            ...send files, not parts
          </Typography.Text>
        }
      />
      <Card
          size={'small'}
        style={{ minHeight: '85vh', backgroundColor: '#1f1f1f' }}
        extra={[
          <Upload {...uploadProps} name="uploaded_data_file">
            <Button type={'primary'} icon={<UploadOutlined/>}>Add Analysis</Button>
          </Upload>,
        ]}
      >
        <Row style={{ paddingTop: '1rem' }} gutter={[36, 16]}>
          <Col span={24}>
            <AnalysisList analysisRecords={analysisRecords} />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Analysis;
