// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Button,
    Card,
    Col,
    Divider,
    Empty,
    List,
    message,
    PageHeader,
    Radio,
    Row,
    Select,
    Space,
    Typography,
    Spin
} from "antd";
import {useAtom, useAtomValue,} from "jotai";
import {address, cartCount, cartItems, cartTotal, companyOrders} from "../../store";
import {ArrowLeftOutlined, DeleteFilled} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import './style.less';


const axios = require('axios');

const CartCheckOut = (props: any) => {
    const [discount, setDiscount] = useState(1);
    const [orderProducts, setOrderProducts] = useAtom(cartItems);
    const [total, setTotal] = useAtom(cartTotal);
    const companyAddress = useAtomValue(address);
    const [addressIndex, setAddressIndex] = useState(0);
    const [count, setCount] = useAtom(cartCount);
    const [processingOrder, setProcessingOrder] = useState(false)
    const [order, setOrder] = useAtom(companyOrders)
    let navigate = useNavigate();

    const updateQuantity = (value: any, productId: any) => {
        console.log(value, productId);
        let updatedProducts = orderProducts.map((item: any) => {
            if (item.product.id === productId) {
                item.quantity = value;
            }
            return item;
        });

        setOrderProducts(updatedProducts);

    };

    const quantitySelection = (item) => {
        return(
                <Select onChange={(value)=> updateQuantity(value, item.product.id)} defaultValue={item.quantity} style={{width: '100%'}}
                       placeholder={'Quantity'}>
                    <Select.Option value={1}>1</Select.Option>
                    <Select.Option value={2}>2</Select.Option>
                    <Select.Option value={3}>3</Select.Option>
                    <Select.Option value={4}>4</Select.Option>
                    <Select.Option value={5}>5</Select.Option>
                    <Select.Option value={6}>6</Select.Option>
                    <Select.Option value={7}>7</Select.Option>
                    <Select.Option value={8}>8</Select.Option>
                    <Select.Option value={9}>9</Select.Option>
                    <Select.Option value={10}>10</Select.Option>
                </Select>
            )
    }

    const removeProduct = (productId: any) => {
        setOrderProducts(orderProducts.filter(item => item.product.id !== productId))
        setCount(count - 1)
    }

    useEffect(() => {
        setTotal(orderProducts.reduce((acc, item) => {
            return acc + item.product.ivaldi_price_usd * item.quantity
        }, 0))
    }, [orderProducts])

    const submitOrder = () => {
        setProcessingOrder(true)
        const userId = JSON.parse(localStorage.getItem('userID'));
        const accessToken = localStorage.getItem('accessToken');
        const companyId = JSON.parse(localStorage.getItem('companyId'));
        const shippingAddress = companyAddress ? companyAddress[addressIndex].id: null;
        const products = orderProducts.map((item: any) => {
            return {
                product_id: item.product.id,
                quantity: item.quantity
            }
        });

        const formData = new FormData();
        formData.append('user', userId);
        formData.append('company', companyId);
        formData.append('address', shippingAddress);
        formData.append('products', JSON.stringify(products));
        formData.append('total', total);
        const config = {
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/api/order/`,
            headers: {
                'Authorization': `Bearer ${accessToken}`},

            data: formData

        };
        axios(config).then(res => {
            console.log(res.data);
            setOrderProducts([]);
            setCount(0);
            message.success('Order placed successfully');
            setProcessingOrder(false)
            setOrder(existingOrders => {
                return [res.data, ...order]
            })

        }).catch(err => {
            console.log(err);
        })
    }


    return(
        <>

            <PageHeader
                title={'Checkout'}
                subTitle={'...send files, not parts'}
                backIcon={<ArrowLeftOutlined style={{color: '#cda83b'}}/>}
                onBack={() => navigate(-1)}
            />

                <Row>
                    <Col span={17}>
                        <Card  title={<Typography.Title level={3}>Items</Typography.Title>} type={'inner'}
                              style={{height: '100%', backgroundColor: '#1f1f1f'}}>
                            <Row>
                                <Col span={24}>
                                    <List
                                        locale={{emptyText: (<Empty description={'No items'}/>)}}
                                        itemLayout="vertical"
                                        dataSource={orderProducts}
                                        renderItem={item => (
                                            <List.Item
                                                actions={[
                                                    <Typography.Title
                                                        level={4}>${item.product.ivaldi_price_usd}</Typography.Title>,
                                                    <>X</>,
                                                    quantitySelection(item),
                                                    <Button type={'link'} icon={<DeleteFilled/>}
                                                            onClick={() => removeProduct(item.product.id)}>Delete</Button>
                                                ]}

                                                extra={[<Typography.Title
                                                    level={4}>${item.product.ivaldi_price_usd * item.quantity}</Typography.Title>]}

                                            >
                                                <List.Item.Meta
                                                    avatar={
                                                        <Card>
                                                            <Avatar size={100}
                                                                    src={item.product.digital_library_files[0].image_url}/>
                                                        </Card>

                                                    }
                                                    title={<Typography.Title
                                                        level={4}>{item.product.part_name}</Typography.Title>}
                                                    description={
                                                        <>
                                                            <div>

                                                            </div>
                                                            <div>
                                                                {item.product.company_part_id}
                                                            </div>
                                                            <div>
                                                                {item.product.product_notes}
                                                            </div>
                                                        </>
                                                    }
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={7}>
                        <Card title={<Typography.Title level={3}>Summary</Typography.Title>}
                              style={{height: '100%', backgroundColor: '#1f1f1f'}}>
                            <Row>
                                <Col span={12}>
                                    <Typography.Title level={4}>
                                        Subtotal:
                                    </Typography.Title>
                                </Col>
                                <Col span={12} style={{textAlign: 'right'}}>
                                    <Typography.Title level={4}>
                                        ${total}
                                    </Typography.Title>
                                </Col>
                                <Col span={12}>
                                    <Typography.Text type={'secondary'}>
                                        Shipping:
                                    </Typography.Text>
                                </Col>
                                <Col span={12} style={{textAlign: 'right'}}>
                                    <Typography.Text type={'secondary'}>
                                        TBD
                                    </Typography.Text>
                                </Col>

                                <Col span={12}>
                                    <Typography.Text type={'secondary'}>
                                        Sales Tax
                                    </Typography.Text>
                                </Col>
                                <Col span={12} style={{textAlign: 'right'}}>
                                    <Typography.Text type={'secondary'}>
                                        TBD
                                    </Typography.Text>
                                </Col>

                                <Col span={12}>
                                    <Typography.Text type={'secondary'}>
                                        Ivaldi discount:
                                    </Typography.Text>
                                </Col>
                                <Col span={12} style={{textAlign: 'right'}}>
                                    <Typography.Text type={'secondary'}>
                                        100%
                                    </Typography.Text>
                                </Col>
                                <Divider/>
                                <Col span={12}>
                                    <Typography.Title level={4}>
                                        Total:
                                    </Typography.Title>
                                </Col>
                                <Col span={12} style={{textAlign: 'right'}}>
                                    <Typography.Title level={4}>
                                        ${total - (total * discount)}
                                    </Typography.Title>
                                </Col>
                                <Divider/>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Typography.Text>Ship to:</Typography.Text>
                                    <div style={{paddingTop: '1rem'}}>
                                        <Radio.Group onChange={(e) => setAddressIndex(e.target.value)} defaultValue={addressIndex}>
                                            <Space direction={'vertical'}>
                                                {companyAddress.map((address: any, index: number) => {
                                                    return (
                                                        <Radio key={index} value={index}>
                                                            {address.full_name}
                                                            <br/>
                                                            {address.street_1}
                                                            <br/>
                                                            {address.city}, {address.state} {address.zip} {address.country}
                                                            <br/>
                                                            {address.phone_number}
                                                        </Radio>
                                                    )
                                                })}
                                            </Space>
                                        </Radio.Group>
                                    </div>

                                </Col>
                            </Row>
                            <Divider/>
                            <Row style={{paddingTop: '2rem'}}>
                                <Col offset={6} span={12} style={{textAlign: 'center'}}>
                                    {
                                        orderProducts.length > 0 ?
                                            <Button onClick={submitOrder} style={{width: '100%'}}
                                                    type={'primary'}>Order</Button>
                                            :
                                            <Button onClick={submitOrder} disabled style={{width: '100%'}}
                                                    type={'primary'}>Order</Button>

                                    }

                                </Col>
                                <Col span={4} offset={2}>
                                    <Spin size={"large"} spinning={processingOrder}/>
                                </Col>
                            </Row>


                        </Card>

                    </Col>

                </Row>
        </>

    )

}

export default CartCheckOut;