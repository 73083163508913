const axios = require('axios');

export const getUserData = async (user: any) => {
    const accesstoken = user.signInUserSession.accessToken.jwtToken;
    const userId = user.attributes.sub;
    localStorage.setItem('accessToken', accesstoken);

    const config = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/get_user/`,
        params: {username: userId},
        headers: {Authorization: `Bearer ${accesstoken}`},
    };

    const response = await axios(config);
    return response.data;

}

export const getCompanyChoices = async (user: any) => {
    const accesstoken = user.signInUserSession.accessToken.jwtToken;

    const config = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/admin/Companies/`,
        headers: {Authorization: `Bearer ${accesstoken}`},
    };

    const response = await axios(config);
    return response.data;

}