// @ts-nocheck
import React, { useState } from 'react';
import {
    Avatar,
    Button,
    Col,
    List,
    Row,
    Tag,
    Typography,
    Card,
    Descriptions, Empty, Spin,
} from 'antd';

import { Link } from 'react-router-dom';
import {Document, Page} from "react-pdf/dist/esm/entry.webpack";

const dateTimeToString = (dateObject: string | number | Date) => {
  const date = new Date(dateObject);
  const currentDateTime = new Date(
    date.getTime() - date.getTimezoneOffset() * -60000,
  );
  return currentDateTime.toLocaleString();
};

const analysisStatus = (analysis: any) => {
  if (analysis.status === 'Completed') {
    return (
      <Descriptions.Item label="Status">
        <Tag color={'green'}>{analysis.status}</Tag>
      </Descriptions.Item>
    );
  } else if (analysis.status === 'In Progress') {
    return (
      <Descriptions.Item label="Status">
        <Tag color={'blue'}>{analysis.status}</Tag>
      </Descriptions.Item>
    );
  } else {
    return (
      <Descriptions.Item label="Status">
        <Tag color={'gold'}>{analysis.status}</Tag>
      </Descriptions.Item>
    );
  }
};

const AnalysisList = (props: any) => {
  const { analysisRecords } = props;


  return (
    <>
      <List
        grid={{ gutter: [36, 18], column: 3 }}
        pagination={{
          pageSize: 9,
          showTotal: (total) => `${total} items`,
          style: { paddingTop: '2rem' },
          size: 'small',
        }}
        dataSource={analysisRecords}
        renderItem={(item: any) => (
          <List.Item key={item.id}>
              {item.report ?
                  <Link to={`/Analysis/${item.id}`} state={item}>
                  <Card
                      style={{backgroundColor: '#1f1f1f', borderRadius: '0.25rem'}}
                      title={<Typography.Text strong>{item.file_name}</Typography.Text>}
                      hoverable={true}
                  >
                      <Row>
                          <Col span={16}>
                              <Descriptions size={'small'} column={1}>
                                  <Descriptions.Item label="ID">{item.id}</Descriptions.Item>
                                  {analysisStatus(item)}
                                  <Descriptions.Item label="Created on">
                                      {dateTimeToString(item.created_on)}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Updated on">
                                      {dateTimeToString(item.modified_on)}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Total rows">
                                      {item.total_rows}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="File size">
                                      {item.file_size}
                                  </Descriptions.Item>
                              </Descriptions>
                          </Col>
                          <Col span={8}>
                              {
                                  item.report ?
                                      <div style={{paddingLeft: '2rem'}}>
                                          <Link to={`/Analysis/${item.id}`} state={item}>
                                              <Document file={{url: item.report}}
                                                        onLoadError={(error) => console.log(error)}>
                                                  <Page pageNumber={1} scale={0.13}/>
                                              </Document>
                                          </Link>
                                      </div> :

                                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={''}/>
                              }

                          </Col>
                      </Row>
                  </Card>
                  </Link>
                  :
                  <Card
                      style={{backgroundColor: '#1f1f1f', borderRadius: '0.25rem'}}
                      title={<Typography.Text strong>{item.file_name}</Typography.Text>}
                      hoverable={false}
                  >
                      <Row>
                          <Col span={16}>
                              <Descriptions size={'small'} column={1}>
                                  <Descriptions.Item label="ID">{item.id}</Descriptions.Item>
                                  {analysisStatus(item)}
                                  <Descriptions.Item label="Created on">
                                      {dateTimeToString(item.created_on)}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Updated on">
                                      {dateTimeToString(item.modified_on)}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Total rows">
                                      {item.total_rows}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="File size">
                                      {item.file_size}
                                  </Descriptions.Item>
                              </Descriptions>
                          </Col>
                          <Col span={8}>
                                  {
                                      item.report ?
                                          <div style={{paddingLeft: '2rem'}}>
                                              <Document file={{url: item.report}}
                                                        onLoadError={(error) => console.log(error)}>


                                                  <Page pageNumber={1} scale={0.13}/>
                                              </Document>
                                          </div> :

                                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={item.status}/>
                                  }
                          </Col>
                      </Row>
                  </Card>
              }
          </List.Item>
        )}
      />
    </>
  );
};

export default AnalysisList;
