// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { Card, Input, message, PageHeader } from 'antd';
import { useAtom } from 'jotai';
import {
  getAdminOrders,
  updateOrderItemStatus,
  updateOrderStatus,
  deleteOrder,
} from './service';
import OrderTableAdmin from './components/OrderTableAdmin';
import { allCompanyOrders } from '../../store';

const OrderAdmin = () => {
  const [orders, setOrders] = useAtom(allCompanyOrders);
  const [filteredData, setFilteredData] = useState(orders);
  const [orderSelect, setOrderSelect] = useState([]);
  const [groupedOrder, setGroupOrder] = useState({});
  const [address, setAddress] = useState(null);
  const [visible, setVisible] = useState(false);

  const onSearch = (value: string) => {
    let result = [];
    result = orders.filter((data) => {
      // @ts-ignore
      const order_id = data.id;
      const order_id_string = order_id.toString();

      return (
        order_id_string.toLowerCase().search(value.toLowerCase()) !== -1 ||
        // @ts-ignore
        data.status.toLowerCase().search(value.toLowerCase()) !== -1
      );
    });
    setFilteredData(result);
  };

  useEffect(() => {
    getAdminOrders().then((data) => {
      setOrders(data);
      setFilteredData(data);
    });
  }, []);

  const handleStatusChange = (orderId, value) => {
    updateOrderStatus(orderId, value).then((data) => {
      message.success('Status updated to ' + value);
      // update the order status in the store
      const newState = filteredData.map((obj) => {
        if (obj.id === orderId) {
          return { ...obj, ...data };
        }
        return obj;
      });
      setFilteredData(newState);
    });
  };

  const handleItemStatusChange = (itemId, value) => {
    updateOrderItemStatus(itemId, value).then((data) => {
      console.log(data);
    });
  };

  const showDrawer = (item: any) => {
    function groupBy(objectArray, property) {
      return objectArray?.reduce((acc, obj) => {
        const key = obj[property]['part_name'];
        if (!acc[key]) {
          acc[key] = [];
        }
        // Add object to list for given key's value
        acc[key].push(obj);
        return acc;
      }, {});
    }

    const group = groupBy(item.order_items, 'product');
    const formatAddress = () => {
      if (item.address.street_2 === '') {
        setAddress(
          <>
            {item.address.full_name}
            <br />
            {item.address.street_1}
            <br />
            {item.address.city}, {item.address.state_province_region}{' '}
            {item.address.zip_code} {item.address.country}
            <br />
            {item.address.phone_number}
          </>,
        );
      } else {
        setAddress(
          <>
            {item.address.full_name}
            <br />
            {item.address.street_1}
            <br />
            {item.address.street_2}
            <br />
            {item.address.city}, {item.address.state_province_region}{' '}
            {item.address.zip_code} {item.address.country}
            <br />
            {item.address.phone_number}
          </>,
        );
      }
    };
    formatAddress();
    setGroupOrder(group);
    setOrderSelect(item);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const orderCancel = (orderId: string) => {
    deleteOrder(orderId)
      .then((response) => {
        message.success('Order cancelled');
      })
      .catch((error) => {
        message.error(error);
      });
  };

  return (
    <>
      <PageHeader title="Admin" subTitle={'Customer Orders'} />

      <Card
        size={'small'}
        style={{ backgroundColor: '#1f1f1f', minHeight: '85vh' }}
        extra={[
          <Input.Search
            onSearch={onSearch}
            style={{
              width: '100%',
              zIndex: 20000,
            }}
            size="middle"
            placeholder="Search Orders"
            enterButton
          />,
        ]}
      >
        <OrderTableAdmin
          handleStatusChange={handleStatusChange}
          handleItemStatusChange={handleItemStatusChange}
          filteredData={filteredData}
          showDrawer={showDrawer}
          orderSelect={orderSelect}
          groupedOrder={groupedOrder}
          address={address}
          visible={visible}
          onClose={onClose}
          orderCancel={orderCancel}
        />
      </Card>
    </>
  );
};

export default OrderAdmin;
