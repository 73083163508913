// @ts-nocheck

import React, { useState } from 'react';
import { Button, Table, Tag, Typography, message } from 'antd';
import OrderAdminDetailDrawer from './OrderAdminDetailDrawer';
import { useAtom } from 'jotai';
import { allCompanyOrders } from '../../../store';
import { updateOrderStatus } from '../service';

const OrderTableAdmin = (props: any) => {
  const {
    handleStatusChange,
    handleItemStatusChange,
    showDrawer,
    orderSelect,
    groupedOrder,
    address,
    visible,
    onClose,
    orderCancel,
  } = props;

  const dateToString = (dateObject: string | number | Date) => {
    const date = new Date(dateObject);
    const currentDate = new Date(
      date.getTime() - date.getTimezoneOffset() * -60000,
    );
    return currentDate.toDateString();
  };

  const dateTimeToString = (dateObject: string | number | Date) => {
    const date = new Date(dateObject);
    const currentDateTime = new Date(
      date.getTime() - date.getTimezoneOffset() * -60000,
    );
    return currentDateTime.toLocaleString();
  };

  // @ts-ignore

  const tableColumns = [
    {
      title: 'Order ID',
      dataIndex: 'id',
      key: 'id',
      render: (text: any, record: any, index: any) => (
        <Button onClick={() => showDrawer(record)} type={'link'}>
          <Typography.Link strong>{text}</Typography.Link>
        </Button>
      ),
      sorter: (a: { id: number }, b: { id: number }) => a.id - b.id,
    },
    {
      title: 'Ordered on',
      dataIndex: 'created_on',
      align: 'center',
      key: 'created_on',
      // @ts-ignore
      sorter: (a: any, b: any) =>
        new Date(a.order_date) - new Date(b.order_date),
      render: (text: any) => dateToString(text),
      responsive: ['lg'],
    },
    {
      title: 'Updated on',
      dataIndex: 'modified_on',
      key: 'modified_on',
      align: 'center',

      //@ts-ignore
      sorter: (a: any, b: any) =>
        new Date(a.modified_on) - new Date(b.modified_on),
      render: (text: any) => dateTimeToString(text),
      responsive: ['lg'],
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      align: 'center',
      render: (text: any, record: any, index: any) => (
        <>{record.company.name}</>
      ),
      responsive: ['lg'],
    },
    {
      title: 'Ordered by',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      render: (text: any, record: any, index: any) => <>{record.user.email}</>,
      responsive: ['lg'],
    },

    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
      render: (text: any, record: any, index: any) => (
        <>{record.order_items.length}</>
      ),
      sorter: (a: any, b: any) => a.order_items.length - b.order_items.length,
      responsive: ['lg'],
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'center',
      render: (text: any, record: any, index: any) => <>{`$ ${text}`}</>,
      responsive: ['lg'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text: any) => <Tag color={'green'}>{text}</Tag>,
      filters: [
        {
          text: 'Received',
          value: 'Received',
        },
        {
          text: 'Processing',
          value: 'Processing',
        },
        {
          text: 'Out for Delivery',
          value: 'Out for Delivery',
        },
        {
          text: 'Delivered',
          value: 'Delivered',
        },
      ],
      onFilter: (value: any, record: { status: string | any[] }) =>
        record.status.indexOf(value) === 0,
    },
    {
      title: 'Canceled',
      dataIndex: 'is_canceled',
      key: 'is_canceled',
      render: (text: any, record: any, index: any) =>
      {
        if(text === false){
          return <Tag color={'green'}>No</Tag>
        }

        return <Tag color={'red'}>Yes</Tag>
      }
    },
  ];

  return (
    <>
      <OrderAdminDetailDrawer
        onClose={onClose}
        visible={visible}
        order={orderSelect}
        groupedOrder={groupedOrder}
        address={address}
        handleStatusChange={handleStatusChange}
        handleItemStatusChange={handleItemStatusChange}
        orderCancel={orderCancel}
      />

      <Table
        size={'small'}
        bordered
        // @ts-ignore
        columns={tableColumns}
        dataSource={props.filteredData}
      />
    </>
  );
};

export default OrderTableAdmin;
