// @ts-nocheck

import React from 'react';
import { Card, Row, Col, Table, Tag } from 'antd';
import { Link } from 'react-router-dom';

const DigitalLibraryList = (props: any) => {
  const { products } = props;

  const columns = [
    {
      title: 'Company Part ID',
      dataIndex: 'company_part_id',
      key: 'company_part_id',
      render: (text: any, record: any) => (
        <Link
          to={`/DigitalLibrary/detail/${record.id}`}
          state={{ productData: record }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: 'MCat Number',
      dataIndex: 'mcat_number',
      key: 'mcatNumber',
      render: (text: any, record: any) => record.digitization.mcat_number,
    },
    {
      title: 'Part Name',
      dataIndex: 'part_name',
      key: 'part_name',
      width: '20%',
    },

    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text: any, record: any) => record.company.name,
    },
    {
      title: 'Category',
      dataIndex: 'category_name',
      key: 'category_name',
      render: (text: any, record: any) => record.category.name,
    },
      {
      title: 'Class',
      key: 'criticality',
      render: (text: any, record: any) => record.digitization.am_class,
    },
    {
      title: 'Created on',
      dataIndex: 'created_on',
      key: 'created_on',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={products}
      pagination={{ pageSize: 15 }}
      size={'small'}
      // bordered={true}
      style={{ backgroundColor: '#1f1f1f' }}
    />
  );
};

export default DigitalLibraryList;
