// @ts-nocheck
import React from 'react';
import {Card, Col, Divider, Form, Input, InputNumber, Row, Select, Tag, Typography, Tooltip} from 'antd';
import {ClockCircleOutlined, DollarOutlined, QuestionCircleOutlined, UserOutlined} from "@ant-design/icons";

const Manufacturing = (props: any) => {


    // convert UTC to local time
      const convertUTCtoLocalTime = (utcTime: string) => {
        const date = new Date(utcTime);
        const localTime = date.toLocaleString();
        return localTime;
    };

    const product = props.productData;

    return (
        <Card style={{backgroundColor: 'transparent', height: '100%'}}>
            <Row gutter={[24, 0]}>
                <Col span={24}>

                      <Form.Item label={'Manufacturing Price (USD)'} name={'manufacturing_price_usd'}>
                        <InputNumber prefix={<DollarOutlined className="site-form-item-icon"/>}/>
                    </Form.Item>

                    <Form.Item label={'Certification/Testing Price (USD)'} name={'testing_price_usd'}>
                        <InputNumber prefix={<DollarOutlined className="site-form-item-icon"/>}/>
                    </Form.Item>

                     <Form.Item label={'Shipping Price (USD)'} name={'shipping_price_usd'}>
                        <InputNumber prefix={<DollarOutlined className="site-form-item-icon"/>}/>
                    </Form.Item>

                    <Form.Item label={'Ivaldi Unit Price (USD)'} name={'ivaldi_price_usd'}>
                        <InputNumber prefix={<DollarOutlined className="site-form-item-icon"/>}/>
                    </Form.Item>

                     <Form.Item label={'Manufacturer'} name={'manufacturer'}>
                        <Input/>
                    </Form.Item>

                    <Form.Item label={'Lead Time (days)'} name={'lead_time_days'}>
                        <InputNumber prefix={<ClockCircleOutlined className="site-form-item-icon"/>}/>
                    </Form.Item>

                    {/*<Form.Item label={'Notes'} name={'product_notes'}>*/}
                    {/*    <Input.TextArea/>*/}
                    {/*</Form.Item>*/}

                </Col>
            </Row>
        </Card>


    );
};

export default Manufacturing;