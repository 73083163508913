// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'antd/dist/antd.dark.less';
import App from './components/App';
import { Amplify } from 'aws-amplify';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { Provider } from 'jotai';
import {
  Authenticator,
  Heading,
  Image,
  useTheme,
  View,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './index.less';
import awsExports from '../src/aws-exports';


Amplify.configure(awsExports);



const LoginComponents = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          height={30}
          alt={''}
          src="https://ivaldi.com/wp-content/uploads/2020/10/Ivaldi_Logo-Half-Reverse.svg"
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <h4 color={`${tokens.colors.neutral['80']}`}>&copy; Ivaldi Group</h4>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={5}
        >
          Sign in to your account
        </Heading>
      );
    },
  },
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Authenticator
    variation="modal"
    hideSignUp={true}
    components={LoginComponents}
  >
    {({ signOut, user }) => (
      <>
        <HashRouter>
          <Provider>
            <App user={user} />
          </Provider>
        </HashRouter>
      </>
    )}
  </Authenticator>,
);
