// @ts-nocheck
import React, {useEffect} from 'react';
import {Menu, Dropdown, Space, Divider, Button, Badge} from 'antd';
import {LogoutOutlined, CaretDownOutlined, ShoppingCartOutlined} from '@ant-design/icons';
import {Auth} from 'aws-amplify';
import {useAtom, useAtomValue} from "jotai";
import {cartCount, address, companyId} from "../../../../store";
import {Link} from "react-router-dom";

import './style.less'

const axios = require('axios');


async function signOut() {
  try {
    await Auth.signOut();
    localStorage.clear()
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

const RightHeaderContent = (props:any) => {
    const itemCount = useAtomValue(cartCount);
    const myCompanyId = useAtomValue(companyId);

    const [companyAddress, setCompanyAddress] = useAtom(address)
    useEffect(() => {
        const companyId = JSON.parse(localStorage.getItem('companyId'));
        const accessToken = localStorage.getItem('accessToken');
        const config ={
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/address/`,
            params: {
                companyId: companyId
            },
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }
        axios(config)
            .then(res => {
                setCompanyAddress(res.data)
            })
            .catch(err => {
                console.log(err)
            })

    }, [myCompanyId])


  const menu = (
    <Menu>
      <Menu.Item onClick={signOut} icon={<LogoutOutlined/>}>Logout</Menu.Item>
    </Menu>
  )


  return (

          <Space>
              <Badge color={'green'} count={itemCount}>
                      <Link to={'/CartCheckOut'}>
                          <Button type={'link'} icon={<ShoppingCartOutlined style={{color: 'white', fontSize: '26px'}}/>}/>
                      </Link>
              </Badge>
              <Divider type={'vertical'}/>
              <Dropdown overlay={menu} trigger={['click']}>
                  <a onClick={e => e.preventDefault()}>
                      <Space>
                          <h4 style={{paddingTop: '2px', color: 'white'}}>{props.user}</h4>
                          <CaretDownOutlined/>
                      </Space>
                  </a>
              </Dropdown>
          </Space>


  )
}

export default RightHeaderContent
