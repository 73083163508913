// @ts-nocheck
import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Divider,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
    Tooltip
} from 'antd';
import { address, cartCount, cartItems } from '../../../../store';
import { useAtom, useAtomValue } from 'jotai';
import {QuestionCircleOutlined, ShoppingCartOutlined} from '@ant-design/icons';

const OrderProduct = (props: any) => {
  const { product } = props;

  const [items, setItems] = useAtom(cartItems);
  const [count, setCount] = useAtom(cartCount);
  const [quantity, setQuantity] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const addressAtom = useAtomValue(address);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const addItemToCart = () => {
    if (
      items.filter((item: any) => item.product.id === product.id).length === 0
    ) {
      setItems((prevState) => [
        ...prevState,
        {
          product: product,
          quantity: quantity,
        },
      ]);
      setCount(count + 1);
      message.success('Product added to cart');
    } else {
      message.warning('Product already in cart');
    }
  };

  function handleQuantityChange(value) {
    setQuantity(value);
  }

  return (
    <Card
      bordered={false}
      style={{
        backgroundColor: '#1f1f1f',
        textAlign: 'center',
      }}
    >
      <Row>
        <Col span={24}>
            <Tooltip title={'Price includes Manufacturing and Shipping'}>
               <h2 style={{ fontWeight: 'bold' }}>
                 ${product.ivaldi_price_usd}
                 <span style={{paddingLeft: '10px'}}>
                   <QuestionCircleOutlined/>
                 </span>
               </h2>

            </Tooltip>
        </Col>
        <Col span={24} style={{ paddingTop: '1rem' }}>
          <Select
            defaultValue={quantity}
            style={{ width: '50%' }}
            placeholder={'Quantity'}
            onChange={handleQuantityChange}
          >
            <Select.Option value={1}>1</Select.Option>
            <Select.Option value={2}>2</Select.Option>
            <Select.Option value={3}>3</Select.Option>
            <Select.Option value={4}>4</Select.Option>
            <Select.Option value={5}>5</Select.Option>
            <Select.Option value={6}>6</Select.Option>
            <Select.Option value={7}>7</Select.Option>
            <Select.Option value={8}>8</Select.Option>
            <Select.Option value={9}>9</Select.Option>
            <Select.Option value={10}>10</Select.Option>
          </Select>
        </Col>
        <Col span={24} style={{ paddingTop: '1rem' }}>
          <div>
            <Button
              icon={<ShoppingCartOutlined style={{fontSize: '24px'}} />}
              style={{ width: '50%', fontWeight: 'bold' }}
              type={'primary'}
              onClick={addItemToCart}
            >
              Add
            </Button>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default OrderProduct;
