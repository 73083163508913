// @ts-nocheck
import {Auth} from "aws-amplify";

const axios = require('axios');
const axiosRetry = require('axios-retry');
axiosRetry(axios, { retries: 3 });

export  const getAnalysis = async () => {
    const accessToken = localStorage.getItem('accessToken');
    const config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/analysis/`,
        headers:{
            'Authorization': `Bearer ${accessToken}`
        },
        params: {
            companyId: JSON.parse(localStorage.getItem('companyId'))
        }

    }
    try{
        const response = await axios(config);
        return response.data;
    }
    catch(error){
        console.log(error);
        if(error.response.status === 401){
           Auth.signOut();
        }
    }

}
