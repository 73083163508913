// @ts-nocheck

import React, { useState } from 'react';
import { Row, Col, Card, PageHeader } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductDescription from './componenets/ProductDescription';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './style.less';
import CostAnalysis from './componenets/CostAnalysis';

const ProductDetail = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const { productData } = location.state;

  const [activeTab, setActiveTab] = useState('Product');

  const tabs = [
    {
      key: 'Product',
      tab: 'Product',
    },
    {
      key: 'CostAnalysis',
      tab: 'Cost Analysis',
    },
  ];

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  const tabContent = {
    Product: <ProductDescription product={productData} />,
    CostAnalysis: <CostAnalysis product={productData} />,
  };

  return (
    <>
      <PageHeader
        backIcon={<ArrowLeftOutlined style={{ color: '#cda83b' }} />}
        onBack={() => navigate('/DigitalLibrary')}
        title={`${productData.company_part_id} - ${productData.part_name}`}
      />
      <Card
        style={{ minHeight: '85vh', backgroundColor: '#1f1f1f' }}
        tabList={tabs}
        activeTabKey={activeTab}
        onTabChange={(key) => {
          onTabChange(key);
        }}
        size={'small'}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>{tabContent[activeTab]}</Col>
        </Row>
      </Card>
    </>
  );
};

export default ProductDetail;
