// @ts-nocheck
import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Card,
  PageHeader,
  Typography,
  Divider,
  Form,
  Button,
  message,
  Tabs, Empty,
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import AnalysisForm from '../AnalysisForm';
import PreviewTable from '../PreviewTable';
import DataInfo from '../DataInfo';
import { updateAnalysis, screen } from '../../services';
import AdminAnalysisResults from "../AdminAnalysisResults";

const AnalysisDetailAdmin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const analysisData = location.state;

  const [tableColumns, setTableColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [columnMap, setColumnMap] = useState({
    id: null,
    description: null,
    lead_time: null,
    cost: null,
    quantity: null,
  });
  const [isScreening, setIsScreening] = useState(false);
  const optionsArray = ['id', 'description', 'lead_time', 'cost', 'quantity'];
  const [options, setOptions] = useState(optionsArray);

  useEffect(() => {
    analysisData.columns.map((eachColumn) => {
      const columnElement = {
        title: eachColumn,
        dataIndex: eachColumn,
        key: eachColumn,
      };

      setTableColumns((tableColumns) => [...tableColumns, columnElement]);
      setIsLoading(false);
    });
  }, []);

  const [form] = Form.useForm();
  const saveForm = () => {
    form.validateFields().then((values) =>
      updateAnalysis(analysisData.id, values)
        .then((res) => {
          const status = values.status;
          message.success(`Status updated to ${status}`);
        })
        .catch((err) => console.log(err)),
    );
  };

  const handleChange = (value: any, record: any) => {
    const filteredArray = options.filter((option) => option !== value);
    setOptions(filteredArray);
    setColumnMap({ ...columnMap, [value]: record.Column });
  };

  const handleOnDeselect = (value) => {
    setOptions([...options, value]);
    setColumnMap({ ...columnMap, [value]: null });
  };

  const screenData = () => {
    const id = analysisData.id;
    console.log(id, columnMap);

    // check if all values are not null in columnMap
    const isNull = Object.values(columnMap).includes(null);
    if (isNull) {
      message.error('Please select all columns');
      return;
    }
    // check if all values are not null in columnMap
    else {
      setIsScreening(true);
      screen(id, columnMap)
        .then((data) => {
          console.log(data);
          setIsScreening(false);
          message.success('Data screened successfully');
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <PageHeader
        title="Analysis"
        onBack={() => navigate('/AnalysisAdmin')}
        subTitle={
          <Typography.Text italic={true} type="secondary">
            {' '}
            File Name: {analysisData.file_name}
            <Divider type="vertical" />
            ID: {analysisData.id}
          </Typography.Text>
        }
      />
      <Card style={{ minHeight: '85vh', backgroundColor: '#1f1f1f' }}>
        <Tabs
          defaultActiveKey="1"
          tabBarExtraContent={
            <>
              <Button>Delete</Button>
              <Divider type="vertical" />
              <Button onClick={saveForm}>Save</Button>
              <Divider type="vertical" />
              <Button htmlType="submit" type={'primary'} onClick={screenData}>
                Screen
              </Button>
            </>
          }
        >
          <Tabs.TabPane tab={'Analysis'} key={'1'}>
            <Row>
              <Col span={14}>
                <Row>
                  <Col span={24}>
                    <AnalysisForm
                      saveForm={saveForm}
                      form={form}
                      analysis={analysisData}
                    />
                  </Col>
                  <Col span={24}>
                    <DataInfo
                      options={options}
                      handleChange={handleChange}
                      handleOnDeselect={handleOnDeselect}
                      info={analysisData.data_info}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Card loading={isScreening} size={'small'} title={'Results'} style={{background: 'transparent', height: '100%'}}>
                      {
                       analysisData.results ? <AdminAnalysisResults analysisData={analysisData} /> :
                           <Empty style={{paddingTop: '10rem'}} description={'No results yet'}
                                  image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                      }

                </Card>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Preview data'}>
            <Row>
              <Col span={24}>
                <PreviewTable
                  tableColumns={tableColumns}
                  analysisData={analysisData}
                  isLoading={isLoading}
                />
              </Col>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </>
  );
};

export default AnalysisDetailAdmin;
