import React from 'react';
import {Divider, Input, Typography} from 'antd';

const {Search} = Input;

const DigitalLibrarySearch = (props:any) => {
    return (
        <>
            <div>
                <Typography.Title level={5}>Search Library</Typography.Title>
            </div>
            <Divider/>
            <Search
                placeholder="search for products..."
                allowClear
                enterButton
                onSearch={props.onProductSearch}
            />
        </>

    )
}

export default DigitalLibrarySearch;
