// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select } from 'antd';

import { getDigitizationRecords } from '../../service';

const NewProductForm = (props: any) => {
  const { visible, onCancel, form, onCreateProject, companies, categories } =
    props;
  const [digitizationRecords, setDigitizationRecords] = useState([]);

  useEffect(() => {
    if (visible) {
      getDigitizationRecords().then((data) => {
        setDigitizationRecords(data);
      });
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title="New Product"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={onCreateProject}
      width={800}
      destroyOnClose
    >
      <Form
        form={form}
        labelAlign={'left'}
        name="form_in_modal"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        preserve={false}
      >
        <Form.Item
          name="company"
          label="Company"
          rules={[{ required: true, message: 'Please Select a Company!' }]}
        >
          <Select placeholder={'Choose a Company'}>
            {companies.map((company: any) => (
              <Select.Option key={company.name} value={company.id}>
                {company.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="company_part_id"
          label="Company Part ID"
          rules={[{ required: true, message: 'Input Product ID for Company!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="part_name"
          label="Part Name"
          rules={[{ required: true, message: 'Enter Part Name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="category"
          label="Category"
          rules={[{ required: true, message: 'Select a Category!' }]}
        >
          <Select>
            {categories.map((category: any) => (
              <Select.Option key={category.id} value={category.id}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="digitization" label="Digitization">
          <Select
            placeholder={'Leave blank for new record or select previous one'}
            allowClear
          >
            {digitizationRecords.map((digitization: any) => (
              <Select.Option key={digitization.id} value={digitization.id}>
                {digitization.mcat_number}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="supplier" label="Supplier">
          <Input />
        </Form.Item>

        <Form.Item name="unit_price_usd" label="Unit price (USD)">
          <Input />
        </Form.Item>

        <Form.Item name="lead_time_days" label="Lead time (days)">
          <Input />
        </Form.Item>

        <Form.Item name="product_notes" label="Notes">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewProductForm;
