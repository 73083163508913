import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const cartCount = atomWithStorage('cartCount', 0);
export const cartItems = atomWithStorage('cartItems', []);
export const cartTotal = atomWithStorage('cartTotal', 0);
export const address = atom([]);
export const amClassChoices = atom([]);
export const digitizationChoices = atom([]);
export const companyChoices = atom([]);
export const categoryChoices = atom([]);
export const ivaldiProduct = atom({});
export const accessToken = atomWithStorage('accessToken', '');
export const companyId = atomWithStorage('companyId', '');
export const allCompanyOrders = atom([]);
export const digitalLibraryProducts = atom([])
export const companyOrders = atom([])
export const companyAnalysis = atom([])
export const isDigitalLibraryLoading = atom(false)
export const digitalLibraryView = atom('tab1')
