// @ts-nocheck
import React from 'react';
import {Col, Row, Card, Timeline, Typography, Divider} from 'antd'

const convertUTCtoLocalTime = (utcTime: string) => {
    const date = new Date(utcTime);
    const localTime = date.toLocaleString();
    return localTime;
};



const ProductHistoryAdmin =(props:any) => {
    const {productHistory, isLoading} = props;

    return(
        <Card style={{backgroundColor: 'transparent', overflowX: "hidden", overflowY: "auto", height: '40rem'}} loading={isLoading}>
            <Row>
                <Col span={24}>
                    <Timeline>
                    {productHistory.map((item:any, index:number) => {
                        return(

                                <Timeline.Item>
                                    <Typography.Text>{convertUTCtoLocalTime(item.date)}</Typography.Text>
                                 <Divider type={'vertical'}/>
                                    <Typography.Text>{item.user}</Typography.Text>
                                    <br/>
                                    {item.action}:
                                    <Typography.Text keyboard>{item.field}</Typography.Text>
                                    <Divider type={'vertical'}/>
                                     from: <Typography.Text keyboard>{item.from}</Typography.Text>
                                    <Divider type={'vertical'}/>
                                   to: <Typography.Text keyboard>{item.to}</Typography.Text>
                                </Timeline.Item>
                        )
                    })}
                    </Timeline>
                </Col>
            </Row>
        </Card>
    )
}

export default ProductHistoryAdmin;