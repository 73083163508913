// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Card, Col, PageHeader, Row, Typography } from 'antd';
import DigitalLibrarySearch from './components/DigitalLibrarySearch';
import ProductCard from './components/ProductCard';
import CategoryFilter from './components/CategoryFilter';
import {
  companyId,
  digitalLibraryProducts,
  isDigitalLibraryLoading,
  digitalLibraryView,
} from '../../store';
import { useAtom, useAtomValue } from 'jotai';
import { getDigitalLibraryData } from './services';
import DigitalLibraryList from './components/DigitalLibraryList';
import ClassFilter from './components/ClassFilter';

const DigitalLibrary = () => {
  const [products, setProducts] = useAtom(digitalLibraryProducts);
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [categories, setCategories] = React.useState([]);
  const myCompanyId = useAtomValue(companyId);
  const isLoading = useAtomValue(isDigitalLibraryLoading);
  const [activeTabKey, setActiveTabKey] = useAtom(digitalLibraryView);

  useEffect(() => {
    setFilteredProducts(products);
    const uniqueCategories = [
      ...new Set(products.map((product) => product.category.name)),
    ];
    setCategories(uniqueCategories);
  }, [myCompanyId, products]);

  const onProductSearch = (value: string) => {
    let result = [];
    result = products.filter((data) => {
      const productName = data.part_name.toLowerCase();
      const category = data.category.name.toLowerCase();
      const partNumber = data.company_part_id.toString().toLowerCase();
      const mcatNumber = data.digitization.mcat_number.toString().toLowerCase();
      return (
        productName.search(value.toLowerCase()) !== -1 ||
        category.search(value.toLowerCase()) !== -1 ||
        partNumber.search(value.toLowerCase()) !== -1 ||
        mcatNumber.search(value.toLowerCase()) !== -1
      );
    });
    setFilteredProducts(result);
  };
  const onCategoryChange = (checkedValues: any) => {
    if (checkedValues.length === 0) {
      setFilteredProducts(products);
    } else {
      let result = [];
      result = products.filter((data) => {
        const category = data.category.name;
        return checkedValues.includes(category);
      });
      setFilteredProducts(result);
    }
  };
  const onClassChange = (checkedClasses: any) => {
    if (checkedClasses.length === 0) {
      setFilteredProducts(products);
    } else {
      let result = [];
      result = products.filter((data) => {
        const classes = data.digitization.am_class;
        return checkedClasses.includes(classes);
      });
      setFilteredProducts(result);
    }
  };
  const amClasses = [
    'Comfort Critical',
    'Business Critical',
    'Safety Critical',
  ];

  const contentList: Record<string, React.ReactNode> = {
    tab1: <ProductCard products={filteredProducts} />,
    tab2: <DigitalLibraryList products={filteredProducts} />,
  };

  const tabList = [
    {
      key: 'tab1',
      tab: 'Grid view',
    },
    {
      key: 'tab2',
      tab: 'Table view',
    },
  ];
  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  return (
    <>
      <PageHeader
        title="Digital Library"
        subTitle={
          <Typography.Text type="secondary" italic={true}>
            {' '}
            ...send files, not parts
          </Typography.Text>
        }
      />

      <Card
        style={{ minHeight: '85vh', backgroundColor: '#1f1f1f' }}
        loading={isLoading}
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={(key) => {
          onTabChange(key);
        }}
      >
        <Row style={{ paddingTop: '1rem' }} gutter={[36, 16]}>
          <Col lg={18} xs={24} md={24}>
            {contentList[activeTabKey]}
          </Col>
          <Col lg={6} xs={24} md={24}>
            <Card style={{ backgroundColor: '#1f1f1f', height: '80vh' }}>
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <DigitalLibrarySearch onProductSearch={onProductSearch} />
                </Col>
                <Col span={24}>
                  <CategoryFilter
                    onCategoryChange={onCategoryChange}
                    categories={categories}
                  />
                </Col>
                <Col span={24}>
                  <ClassFilter
                    onClassChange={onClassChange}
                    amClasses={amClasses}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export default DigitalLibrary;
