import { Auth } from 'aws-amplify';

const axios = require('axios');

export const getAdminOrders = async () => {
  const accessToken = localStorage.getItem('accessToken');
  const config = {
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/api/admin/OrderAdmin/`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const updateOrderStatus = async (orderId: any, status: any) => {
  const accessToken = localStorage.getItem('accessToken');
  const config = {
    method: 'patch',
    url: `${process.env.REACT_APP_API_URL}/api/admin/OrderAdmin/${orderId}/`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      status: status,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const updateOrderItemStatus = async (orderItemId: any, status: any) => {
  const accessToken = localStorage.getItem('accessToken');
  const config = {
    method: 'patch',
    url: `${process.env.REACT_APP_API_URL}/api/admin/OrderItem/${orderItemId}/`,
    headers: { Authorization: `Bearer ${accessToken}` },
    data: {
      status: status,
    },
  };

  const response = await axios(config);
  return response.data;
};

export const deleteOrder = async (orderId: string) => {
    const accessToken = localStorage.getItem('accessToken');
  const config = {
    method: 'delete',
    url: `${process.env.REACT_APP_API_URL}/api/admin/OrderAdmin/${orderId}/`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  };
  const response = await axios(config);
  return response.data;
}
