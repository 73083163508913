// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  Avatar,
  Card,
  Col,
  Collapse,
  Descriptions,
  Divider,
  List,
  PageHeader,
  Row,
  Tag,
  Typography,
  Steps,
} from 'antd';
import { ArrowLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

import { getOrderDetail } from '../services';

const { Panel } = Collapse;

const dateTimeToString = (dateObject: any) => {
  const date = new Date(dateObject);
  const currentDateTime = new Date(
    date.getTime() - date.getTimezoneOffset() * -60000,
  );
  return currentDateTime.toLocaleString();
};

function groupBy(objectArray, property) {
  return objectArray?.reduce((acc, obj) => {
    const key = obj[property]['part_name'];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
}

const orderSteps = (orderInfo, orderAddress) => {
  const status = orderInfo.status;
  const statusMapping = {
    Received: 0,
    Processing: 1,
    'Out for Delivery': 2,
    Delivered: 3,
  };
  const current = statusMapping[status];
  return (
    <Steps current={current}>
      <Steps.Step
        title={'Received'}
        description={
          <>
            Ordered on:
            <div style={{paddingTop: '10px'}}>
              <Tag>
                  {dateTimeToString(orderInfo.created_on)}
              </Tag>
            </div>
          </>
        }
      />
      <Steps.Step title={'Processing'} description={
          <>
              Ordered by:
              <div style={{paddingTop: '10px'}}>
              <Tag>
                  {orderInfo.user_email}
              </Tag>
              </div>


          </>

      } />
      <Steps.Step title={'Out for Delivery'}
      description={<>
          Shipped to:
          <div style={{paddingTop: '10px'}}>
              <Tag>{orderAddress}</Tag>
          </div>

      </>}
      />
      <Steps.Step title={'Delivered'} />
    </Steps>
  );
};

const OrderDetail = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const order = location.state;

  const [isloading, setIsLoading] = useState(true);
  const [orderData, setOrderData] = useState([]);
  const [address, setAddress] = useState(null);
  const [groupedOrder, setGroupOrder] = useState({});

  useEffect(() => {
    getOrderDetail(order.id)
      .then((data) => {
        setOrderData(data);
        setIsLoading(false);
        const group = groupBy(data.order_items, 'product');
        setGroupOrder(group);
        const formatAddress = () => {
          if (data.address.street_2 === '') {
            setAddress(
              <>
                {data.address.full_name}
                <br />
                {data.address.street_1}
                <br />
                {data.address.city}, {data.address.state_province_region}{' '}
                {data.address.zip_code} {data.address.country}
                <br />
                {data.address.phone_number}
              </>,
            );
          } else {
            setAddress(
              <>
                {data.address.full_name}
                <br />
                {data.address.street_1}
                <br />
                {data.address.street_2}
                <br />
                {data.address.city}, {data.address.state_province_region}{' '}
                {data.address.zip_code} {data.address.country}
                <br />
                {data.address.phone_number}
              </>,
            );
          }
        };
        formatAddress();
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <PageHeader
        backIcon={<ArrowLeftOutlined style={{ color: '#cda83b' }} />}
        onBack={() => navigate('/Orders')}
        title="Order details"
        subTitle={<Typography.Text italic={true}>{order.id}</Typography.Text>}
      />

      <Card
        style={{ minHeight: '85vh', backgroundColor: '#1f1f1f' }}
        loading={isloading}
      >
        <Row gutter={[0, 24]}>
            <Col span={24}>
                <Card style={{background: 'transparent'}} bordered={false}>
                    <Row>
                        <Col span={24}>
                            {orderSteps(orderData, address)}
                        </Col>
                        <Divider />
                        <Col span={24}>
                            <div>
                                <Typography.Title level={4}>Items</Typography.Title>
                            </div>
                            <div style={{paddingTop: '1rem'}}>
                                <Collapse
                                    defaultActiveKey={Object.keys(groupedOrder)[0]}
                                    bordered={false}
                                    expandIcon={({isActive}) => (
                                        <CaretRightOutlined rotate={isActive ? 90 : 0}/>
                                    )}
                                >
                                    {Object.entries(groupedOrder)?.map(([key, value]) => {
                                        return (
                                            <Panel
                                                header={
                                                    <>
                                                        {key} x {value.length}
                                                    </>
                                                }
                                                key={key}
                                            >
                                                <List
                                                    itemLayout="horizontal"
                                                    size="large"
                                                    dataSource={value}
                                                    renderItem={(item) => (
                                                        <List.Item>
                                                            <List.Item.Meta
                                                                avatar={
                                                                    <Avatar
                                                                        src={
                                                                            item.product.digital_library_files[0]
                                                                                .image_url
                                                                        }
                                                                        style={{background: 'transparent'}}
                                                                    />
                                                                }
                                                                title={
                                                                    <>
                                                                        {`Product ID: ${item.product.company_part_id}`}
                                                                    </>
                                                                }
                                                                description={
                                                                    <Tag color={'green'}>{item.status}</Tag>
                                                                }
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                            </Panel>
                                        );
                                    })}
                                </Collapse>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
      </Card>
    </>
  );
};

export default OrderDetail;
