import {Auth} from "aws-amplify";

const axios = require('axios');

export const getDigitalLibraryData = async (companyId: any) => {
  const accessToken = localStorage.getItem('accessToken');
  const config = {
    url: `${process.env.REACT_APP_API_URL}/api/digitalLibrary/`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: { companyId: companyId },
  };
    const response = await axios(config);
    return response.data;
}

