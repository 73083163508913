const axios = require('axios');

export const getOrders = async (companyId: any) => {
    const accessToken = localStorage.getItem('accessToken');
    const config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/order/`,
        params: {
            companyId: companyId,
        },
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    };

    const response = await axios(config);
    return response.data;
}

export const getOrderDetail = async (orderId: any) => {
    const accessToken = localStorage.getItem('accessToken');
    const config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/order/${orderId}/get_items/`,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    };

    const response = await axios(config);
    return response.data;

}