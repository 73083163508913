// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  Card,
  Typography,
  Row,
  Col,
  Statistic,
  Divider,
  Descriptions,
} from 'antd';
import { Column } from '@ant-design/plots';

const AdminAnalysisResults = (props: any) => {
  const { analysisData } = props;

  const chartConfig = {
    data: analysisData.results.chartData,
    autoFit: true,
    xAxis: {
      title: {
        text: 'Category',
      },
      label: {
        autoRotate: true,
      },
      grid: {
        line: {
          style: {
            stroke: 'gray',
            lineWidth: 1,
            lineDash: [4, 5],
            strokeOpacity: 0.2,
            shadowColor: 'black',
            shadowBlur: 10,
            shadowOffsetX: 5,
            shadowOffsetY: 5,
            cursor: 'pointer',
          },
        },
      },
    },
    xField: 'category',
    yField: 'found',
    label: {
      position: 'middle',
      style: {
        fontSize: 18,
        fontWeight: 'bold',
        fill: 'white',
      },
    },
  };

  return (
    <Row>
      <Col span={8}>
        <Statistic
          prefix={'$'}
          precision={2}
          title="Total Spend"
          value={analysisData.results.categoriesTotalSpend}
          valueStyle={{fontSize: '18px'}}
        />
      </Col>
      <Col span={8}>
        <Statistic
          title={'Total Products'}
          value={analysisData.results.categoriesTotalProducts}
          valueStyle={{fontSize: '18px'}}
        />
      </Col>
      <Col span={8}>
        <Statistic
          title={'Total Qty Ordered'}
          value={analysisData.results.categoriesTotalQty}
          valueStyle={{fontSize: '18px'}}
        />{' '}
      </Col>

      <Col span={24} style={{ paddingTop: '10rem' }}>
        <Card size={'small'} style={{background: 'transparent'}}>
          <Column {...chartConfig} />
        </Card>
      </Col>
    </Row>
  );
};

export default AdminAnalysisResults;
