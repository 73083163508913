// @ts-nocheck
import React, { useState, useLayoutEffect, useEffect } from 'react';
import { Layout, Menu, Card, Space } from 'antd';
import routes from '../../routes';
import { Routes, Route, Link, useLocation, Navigate } from 'react-router-dom';
import RightHeaderContent from './components/RightHeaderContent';
import './index.less';
import ProductDetail from '../DigitalLibrary/components/ProductDetail';
import ProductDetailAdmin from '../ProductAdmin/components/ProductDetailAdmin';
import ProductAdmin from '../ProductAdmin';
import {
  UserOutlined,
  SettingOutlined,
  ScheduleOutlined,
  TeamOutlined,
  AppstoreOutlined,
  DotChartOutlined,
} from '@ant-design/icons';
import CartCheckOut from '../CartCheckOut';
import { accessToken, companyId, digitalLibraryProducts, isDigitalLibraryLoading } from '../../store';
import { useAtom} from 'jotai';
import {companyChoices, companyOrders} from "../../store";
import OrderAdmin from '../OrderAdmin';
import { getUserData, getCompanyChoices } from './services';
import {getDigitalLibraryData} from "../DigitalLibrary/services";
import {getOrders} from "../Orders/services";
import AnalysisAdmin from '../AnalysisAdmin';
import AnalysisDetailAdmin from "../AnalysisAdmin/components/AnalysisDetailAdmin";
import AnalysisResult from "../AnalysisResult";
import OrderDetail from "../Orders/components/OrderDetail";

const { Header, Content, Sider } = Layout;

// @ts-ignore
const App = (props) => {
  const { user } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [id, setId] = useAtom(companyId);
  const [companyChoicesAtom, setCompanyChoicesAtom] = useAtom(companyChoices)
  const [products, setProducts] = useAtom(digitalLibraryProducts)
  const [orders, setOrders] = useAtom(companyOrders)
  const [digitalLibraryLoading, setLibraryLoading] = useAtom(isDigitalLibraryLoading)

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };
  const location = useLocation();

  useLayoutEffect(() => {
    getUserData(user)
      .then((data) => {
        setId(data.company.id);
        localStorage.setItem('userID', JSON.stringify(data.uuid));

          setLibraryLoading(true)

        getDigitalLibraryData(data.company.id)
            .then((products: any) => {
              setProducts(products);
                setLibraryLoading(false)
            })
            .catch((err: any) => {
              console.log(err);
            });

          getOrders(data.company.id).then(response => {
            setOrders(response)
          }).catch(err => console.log(err))


      })
      .catch((err) => {
        console.log(err);
      });
    const email = user.attributes.email;
    const domain = email.split('@')[1];
    if (domain === 'ivaldi.com') {
      setIsAdmin(true);
      getCompanyChoices(user).then((data) => {
        setCompanyChoicesAtom(data)
      }).catch(err => console.log(err))
    }


  }, []);



  const ProtectedRoute = ({ children }) => {
    if (!isAdmin) {
      return <Navigate replace to={'/DigitalLibrary'} />;
    }
    return children;
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        className="header"
        style={{ position: 'fixed', zIndex: 1, width: '100%' }}
      >
        <span className="logo">
          <img
            height={18}
            src={
              'https://ivaldi.com/wp-content/uploads/2020/10/Ivaldi_Logo-Half-Reverse.svg'
            }
          />
        </span>
        <span className="right-menu-content">
          <Space size={'large'}>
            <RightHeaderContent user={user.attributes.email} />
          </Space>
        </span>
      </Header>

      <Layout>
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
          <Menu
            selectedKeys={[location.pathname]}
            mode="inline"
            style={{ height: '100%', borderRight: 0 }}
          >
            {routes.map((item) => (
              <Menu.Item key={item.link} icon={item.icon}>
                <Link to={item.link}>{item.name}</Link>
              </Menu.Item>
            ))}
            {isAdmin && (
              <Menu.SubMenu
                key={'AdminMenu'}
                title={'Admin'}
                icon={<SettingOutlined />}
              >
                <Menu.Item key={'/AnalysisAdmin'} icon={<DotChartOutlined />}>
                  <Link to={'/AnalysisAdmin'}>Analysis</Link>
                </Menu.Item>
                <Menu.Item key={'/ProductAdmin'} icon={<AppstoreOutlined />}>
                  <Link to={'/ProductAdmin'}>Products</Link>
                </Menu.Item>
                <Menu.Item key={'/OrderAdmin'} icon={<ScheduleOutlined/>}>
                  <Link to={'/OrderAdmin'}>View Orders</Link>
                </Menu.Item>
                <Menu.Item disabled key={'/Companies'} icon={<TeamOutlined />}>
                  <Link to={'/Admin'}>Companies</Link>
                </Menu.Item>
                <Menu.Item disabled key={'users'} icon={<UserOutlined />}>
                  <Link to={'/Admin'}>Users</Link>
                </Menu.Item>
              </Menu.SubMenu>
            )}
          </Menu>
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content className="site-layout-background">
            <Card
              type={'inner'}
              bordered={false}
              size={'small'}
              style={{ padding: '35px', minHeight: '100vh' }}
            >
              <Routes>
                {routes.map((item) => {
                  return <Route path={item.link} element={item.element} />;
                })}
                <Route
                  path={'/DigitalLibrary/detail/:id'}
                  element={<ProductDetail />}
                />
                <Route
                  path={'*'}
                  element={<Navigate replace to={'/DigitalLibrary'} />}
                />

                <Route
                    path={'/Orders/:id'}
                    element={<OrderDetail/>}
                />

                <Route
                  path={'/ProductAdmin'}
                  element={
                    <ProtectedRoute>
                      <ProductAdmin />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={'/OrderAdmin'}
                  element={
                    <ProtectedRoute>
                      <OrderAdmin />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={'/AnalysisAdmin'}
                  element={
                    <ProtectedRoute>
                      <AnalysisAdmin />
                    </ProtectedRoute>
                  }
                />
                <Route
                    path={'/AnalysisAdmin/:id'}
                    element={
                      <ProtectedRoute>
                        <AnalysisDetailAdmin/>
                      </ProtectedRoute>
                    }
                />

                <Route
                  path={'/ProductAdmin/:id'}
                  element={
                    <ProtectedRoute>
                      <ProductDetailAdmin />
                    </ProtectedRoute>
                  }
                />

                <Route
                path={'Analysis/:id'}
                element={
                  <AnalysisResult/>
                }
                />
                <Route path={'/CartCheckOut'} element={<CartCheckOut/>} />
              </Routes>
            </Card>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

// @ts-ignore
export default App;
