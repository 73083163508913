// @ts-nocheck
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Input,
  Checkbox,
  PageHeader,
  Typography,
  Table,
  Tag,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { getAdminAnalysis } from './services';
import { Link } from 'react-router-dom';

const dateTimeToString = (dateObject: string | number | Date) => {
  const date = new Date(dateObject);
  const currentDateTime = new Date(
    date.getTime() - date.getTimezoneOffset() * -60000,
  );
  return currentDateTime.toLocaleString();
};

const AnalysisAdmin = () => {
  const [analysis, setAnalysis] = useState([]);
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getAdminAnalysis().then((data) => {
      setAnalysis(data);
      setLoading(false)
    });
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '20%',
      render: (text, record) => <Link to={`/AnalysisAdmin/${text}`} state={record}>{text}</Link>,
    },
    {
      title: 'File Name',
      dataIndex: 'file_name',
      key: 'file_name',
      ellipsis: true,
    },
    {
      title: 'Created on',
      dataIndex: 'created_on',
      key: 'created_on',
      ellipsis: true,
      render: (text: string | number | Date) => {
        return dateTimeToString(text);
      },
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      render: (text, record, index) => <>{record.company.name}</>,
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      ellipsis: true,
      render: (text, record, index) => <>{record.user.email}</>,
    },
    {
      title: 'Total rows',
      dataIndex: 'total_rows',
      key: 'total_rows',
    },
    {
      title: 'File size',
      dataIndex: 'file_size',
      key: 'file_size',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'user',
      render: (text, record, index) => {
        if(text === 'Pending'){
          return <Tag color="gold">{text}</Tag>
        }
        if(text === 'Completed'){
            return <Tag color="green">{text}</Tag>
        }
        if(text === 'In Progress'){
            return <Tag color="blue">{text}</Tag>
        }
      }

    },
  ];

  return (
    <>
      <PageHeader
        title="Admin"
        subTitle={
          <Typography.Text italic={true} type="secondary">
            {' '}
            Analysis
          </Typography.Text>
        }
      />
      <Card style={{ minHeight: '85vh', backgroundColor: '#1f1f1f' }} loading={isLoading}>
        <Row style={{ paddingTop: '1rem' }}>
          <Col span={24}>
            <Table bordered={true} columns={columns} dataSource={analysis} size={'large'}/>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default AnalysisAdmin;
