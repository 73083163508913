// @ts-nocheck
import React from 'react';
import {
  Col,
  Collapse,
  Drawer,
  Row,
  List,
  Tag,
  Typography,
  Avatar,
  Divider,
  Descriptions,
  Button,
  Card,
  Select,
  Popconfirm,
  Message,
  message,
} from 'antd';

import { CaretRightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const dateTimeToString = (dateObject: any) => {
  const date = new Date(dateObject);
  const currentDateTime = new Date(
    date.getTime() - date.getTimezoneOffset() * -60000,
  );
  return currentDateTime.toLocaleString();
};

const OrderAdminDetailDrawer = (props: any) => {
  const {
    groupedOrder,
    order,
    address,
    handleStatusChange,
    handleItemStatusChange,
    onClose,
    visible,
    orderCancel,
  } = props;

  const statusSelection = (order: any) => {
    const statusOptions = {
      Received: 'Received',
      Processing: 'Processing',
      'Out for Delivery': 'Out for Delivery',
      Delivered: 'Delivered',
    };
    return (
      <Select
        bordered={false}
        style={{ margin: '-7px' }}
        defaultValue={order.status}
        onChange={(value) => handleStatusChange(order.id, value)}
      >
        {Object.keys(statusOptions).map((key) => (
          <Select.Option key={key} value={key}>
            <Tag color={'green'}>{key}</Tag>
          </Select.Option>
        ))}
      </Select>
    );
  };

  const itemStatusSelection = (item) => {
    const itemStatusOptions = {
      Printing: 'Printing',
      'Quality Control': 'Quality Control',
      Complete: 'Complete',
    };

    return (
      <Select
        bordered={false}
        style={{ margin: '-7px' }}
        defaultValue={item.status}
        onChange={(value) => handleItemStatusChange(item.id, value)}
      >
        {Object.keys(itemStatusOptions).map((key) => (
          <Select.Option key={key} value={key}>
            <Tag color={'green'}>{key}</Tag>
          </Select.Option>
        ))}
      </Select>
    );
  };

  return (
    <Drawer
      title={<Typography.Title level={4}>Order# {order.id}</Typography.Title>}
      width={900}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      extra={
        <>
          <Button size={'small'}>Archive</Button>
          <Divider type={'vertical'} />
          <Popconfirm
            title={'Are you sure you want to cancel this order?'}
            onConfirm={() => orderCancel(order.id)}
          >
            <Button size={'small'}>Cancel order</Button>
          </Popconfirm>
        </>
      }
    >
      <Row gutter={[0, 24]}>
        <Card>
          <Col span={24}>
            <Descriptions
              title={<Typography.Title level={4}>Summary</Typography.Title>}
              column={2}
            >
              <Descriptions.Item label="Ordered on">
                {dateTimeToString(order.created_on)}
              </Descriptions.Item>
              <Descriptions.Item label="Ordered by">
                {order.user ? order.user.email : ''}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                {statusSelection(order)}
              </Descriptions.Item>
              <Descriptions.Item label="Total (USD)">
                {order.total}
              </Descriptions.Item>
              <Descriptions.Item label="Address">{address}</Descriptions.Item>
            </Descriptions>
          </Col>
          <Divider />
          <Col span={24}>
            <div>
              <Typography.Title level={4}>Items</Typography.Title>
            </div>
            <div style={{ paddingTop: '1rem' }}>
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
              >
                {Object.entries(groupedOrder)?.map(([key, value]) => {
                  return (
                    <Panel
                      header={
                        <>
                          {key} x {value.length}
                        </>
                      }
                      key={key}
                    >
                      <List
                        itemLayout="vertical"
                        size="small"
                        dataSource={value}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  src={
                                    item.product.digital_library_files[0]
                                      .image_url
                                  }
                                  style={{ background: 'transparent' }}
                                />
                              }
                              title={
                                <Typography.Text strong>
                                  {`Part ID: ${item.id}`}
                                </Typography.Text>
                              }
                              description={
                                <Row>
                                  <Col span={12}>
                                    Status: {itemStatusSelection(item)}
                                  </Col>
                                  <Col span={12}>
                                    Updated on:{' '}
                                    {dateTimeToString(item.modified_on)}
                                  </Col>
                                </Row>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
          </Col>
        </Card>
      </Row>
    </Drawer>
  );
};

export default OrderAdminDetailDrawer;
