// @ts-nocheck
import React from 'react';
import {Card, Col, Divider, Form, Input, InputNumber, Row, Select, Tag, Typography, Tooltip} from 'antd';
import {ClockCircleOutlined, DollarOutlined, QuestionCircleOutlined, UserOutlined} from "@ant-design/icons";
import {useAtomValue} from "jotai";
import {categoryChoices, companyChoices} from "../../../../store";


const ProductForm = (props: any) => {

    const companyChoicesAtom = useAtomValue(companyChoices);
    const categoryChoicesAtom = useAtomValue(categoryChoices);

    // convert UTC to local time
      const convertUTCtoLocalTime = (utcTime: string) => {
        const date = new Date(utcTime);
        const localTime = date.toLocaleString();
        return localTime;
    };

    const product = props.productData;
    const {isComplete} = props

    return (
        <Card style={{backgroundColor: 'transparent', height: '100%'}}>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                  <Form.Item label={'ID'} labelCol={{span: 8}} wrapperCol={{span: 24}}>
                      <Typography.Text disabled>{product.id}</Typography.Text>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={'Created on'} labelCol={{span: 8}} wrapperCol={{span: 24}}>
                        {
                            <Typography.Text disabled>{convertUTCtoLocalTime(product.created_on)}</Typography.Text>
                        }
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={'Company'}
                        name={'company'}>
                        <Select>
                            {companyChoicesAtom.map((company: any) => (
                                <Select.Option key={company.id} value={company.id}>
                                    {company.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item label={'Company Part ID'} name={'company_part_id'}>
                        <Input/>
                    </Form.Item>

                    <Form.Item label={'Part name'} name={'part_name'}>
                        <Input/>
                    </Form.Item>

                    <Form.Item label={'Category'} name={'category'}>
                        <Select>
                            {categoryChoicesAtom.map((category: any) => (
                                <Select.Option key={category.id} value={category.id}>
                                    {category.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item label={'Supplier'} name={'supplier'}>
                        <Input prefix={<UserOutlined className="site-form-item-icon"/>}/>
                    </Form.Item>


                    <Form.Item label={'Unit Price (USD)'} name={'unit_price_usd'}>
                        <InputNumber prefix={<DollarOutlined className="site-form-item-icon"/>}/>
                    </Form.Item>

                    {/*  <Form.Item label={'Mfg Price (USD)'} name={'manufacturing_price_usd'}>*/}
                    {/*    <InputNumber prefix={<DollarOutlined className="site-form-item-icon"/>}/>*/}
                    {/*</Form.Item>*/}

                    {/* <Form.Item label={'Shipping Price (USD)'} name={'shipping_price_usd'}>*/}
                    {/*    <InputNumber prefix={<DollarOutlined className="site-form-item-icon"/>}/>*/}
                    {/*</Form.Item>*/}

                    {/*<Form.Item label={'Ivaldi Unit Price (USD)'} name={'ivaldi_price_usd'}>*/}
                    {/*    <InputNumber prefix={<DollarOutlined className="site-form-item-icon"/>}/>*/}
                    {/*</Form.Item>*/}

                    {/* <Form.Item label={'Manufacturer'} name={'manufacturer'}>*/}
                    {/*    <Input/>*/}
                    {/*</Form.Item>*/}

                    {/*<Form.Item label={'Lead Time (days)'} name={'lead_time_days'}>*/}
                    {/*    <InputNumber prefix={<ClockCircleOutlined className="site-form-item-icon"/>}/>*/}
                    {/*</Form.Item>*/}

                    <Form.Item label={'Notes'} name={'product_notes'}>
                        <Input.TextArea/>
                    </Form.Item>

                    <Form.Item label={'Published'}>
                        {isComplete ? <Tag color={'green'}>True</Tag> : <Tag color={'red'}>False</Tag>}
                    </Form.Item>
                </Col>
            </Row>
        </Card>


    );
};

export default ProductForm;