// @ts-nocheck

import React from 'react';
import {Table, Tag } from 'antd';
import {Link} from  'react-router-dom'

const OrderTable = (props: { filteredData: readonly any[] | undefined }) => {


  const dateToString = (dateObject: string | number | Date) => {
    const date = new Date(dateObject);
    const currentDate = new Date(
      date.getTime() - date.getTimezoneOffset() * -60000,
    );
    return currentDate.toDateString();
  };

  const dateTimeToString = (dateObject: string | number | Date) => {
    const date = new Date(dateObject);
    const currentDateTime = new Date(
      date.getTime() - date.getTimezoneOffset() * -60000,
    );
    return currentDateTime.toLocaleString();
  };

  // @ts-ignore
  // @ts-ignore
  const tableColumns = [
    {
      title: 'Order ID',
      dataIndex: 'id',
      key: 'id',
      render: (text: any, record: any, index: any) => (
          <Link to={`/Orders/${text}`} state={record}>
            {text}
          </Link>
      ),
      sorter: (a: { id: number }, b: { id: number }) => a.id - b.id,
    },
    {
      title: 'Ordered on',
      dataIndex: 'created_on',
      align: 'center',
      key: 'created_on',
      // @ts-ignore
      sorter: (a: any, b: any) =>
        new Date(a.order_date) - new Date(b.order_date),
      render: (text: any) => dateToString(text),
      responsive: ['lg'],

    },
    {
      title: 'Updated on',
      dataIndex: 'modified_on',
      key: 'modified_on',
      align: 'center',

      //@ts-ignore
      sorter: (a: any, b: any) =>
        new Date(a.modified_on) - new Date(b.modified_on),
      render: (text: any) => dateTimeToString(text),
      responsive: ['lg'],

    },
    {
      title: 'Ordered by',
      dataIndex: 'user',
      key: 'user',
      align: 'center',
      responsive: ['lg'],

    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'center',
      render: (text: any, record: any, index: any) => <>{`$ ${text}`}</>,
      responsive: ['lg'],

    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (
        text:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined,
      ) => <Tag color={'green'}>{text}</Tag>,
      filters: [
        {
          text: 'Received',
          value: 'Received',
        },
        {
          text: 'Processing',
          value: 'Processing',
        },
        {
          text: 'Out for Delivery',
          value: 'Out for Delivery',
        },
        {
          text: 'Delivered',
          value: 'Delivered',
        },
      ],
      onFilter: (value: any, record: { status: string | any[] }) =>
        record.status.indexOf(value) === 0,
    },
  ];

  return (
    <>
      <Table
        size={'large'}
        // @ts-ignore
        columns={tableColumns}
        dataSource={props.filteredData}
      />
    </>
  );
};

export default OrderTable;
