//@ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  Form,
  Card,
  Col,
  Row,
  Tag,
  Typography,
  Select,
  Upload,
  Button,
  message,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Auth } from 'aws-amplify';

// convert UTC to local time
const convertUTCtoLocalTime = (utcTime: string) => {
  const date = new Date(utcTime);
  const localTime = date.toLocaleString();
  return localTime;
};

const AnalysisForm = (props: any) => {
  const { analysis, form } = props;
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (analysis.report) {
      const uploadedFiles = {
        uid: analysis.id,
        name: getFileName(analysis.report),
        status: 'done',
      };
      setFiles([uploadedFiles]);
    }
  }, []);

  const getFileName = function (str) {
    return str.split('\\').pop().split('/').pop();
  };

  const fileUploadProps = {
    multiple: false,
    name: 'report',
    method: 'patch',
    action: `${process.env.REACT_APP_API_URL}/api/admin/AdminAnalysis/${analysis.id}/`,
    headers: {
      authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    onChange(info: any) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Form
      form={form}
      name={'AnalysisForm'}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={analysis}
      labelAlign={'left'}
      size={'small'}
    >
      <Row>
        <Col span={24}>
          <Card style={{ background: 'transparent' }} title={'Information'}>
            <Col span={24}>
              <Form.Item label={'ID'}>
                <Typography.Text disabled>{analysis.id}</Typography.Text>
              </Form.Item>

              <Form.Item label={'Created on'}>
                {
                  <Typography.Text disabled>
                    {convertUTCtoLocalTime(analysis.created_on)}
                  </Typography.Text>
                }
              </Form.Item>
              <Form.Item label={'Created by'}>
                {
                  <Typography.Text disabled>
                    {analysis.user.email}
                  </Typography.Text>
                }
              </Form.Item>

              <Form.Item label={'Company'} name={'company'}>
                {
                  <Typography.Text disabled>
                    {analysis.company.name}
                  </Typography.Text>
                }
              </Form.Item>
              <Form.Item label={'Status'} name={'status'}>
                <Select default={'Pending'} style={{ width: 125 }}>
                  <Select.Option value={'Pending'}>
                    <Tag style={{ border: 'none' }} color={'green'}>
                      Pending
                    </Tag>
                  </Select.Option>
                  <Select.Option value={'In Progress'}>
                    <Tag style={{ border: 'none' }} color={'green'}>
                      In Progress
                    </Tag>
                  </Select.Option>
                  <Select.Option value={'Completed'}>
                    <Tag style={{ border: 'none' }} color={'green'}>
                      Completed
                    </Tag>
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label={'Report'}>
                <Upload name={'report'} {...fileUploadProps} fileList={files}>
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default AnalysisForm;
