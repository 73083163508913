const axios = require('axios');
const axiosRetry = require('axios-retry');

axiosRetry(axios, {retries:3});

export async function getDigitizationRecords() {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/api/admin/digitization/`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    const response = await axios(config);
    return await response.data;
  } catch (error) {
    console.log(error);
  }
}
