// @ts-nocheck
import React from 'react';
import {Button, Card, Col, Form, Input, Row, Select, Upload, message} from 'antd';
import {useAtomValue} from "jotai";
import {digitizationChoices, amClassChoices} from "../../../../store";
import {UploadOutlined} from "@ant-design/icons";
const axios = require('axios');

const DigitizationAdmin = (props:any) => {
    const {product, fileUploadProps} = props;
    const digitizationChoicesAtom = useAtomValue(digitizationChoices);
    const amClassChoicesAtom = useAtomValue(amClassChoices);



    // @ts-ignore
    return(
        <Card  style={{backgroundColor: 'transparent', height:'100%'}}>
            <Row>
                <Col span={24}>
                        <Form.Item label={'MCat Number'} name={'mcat_number'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item label={'Status'} name={'status'}>
                            <Select>
                                {
                                    digitizationChoicesAtom.map((choice:any) => {
                                        return <Select.Option key={choice} value={choice}>{choice}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>


                        <Form.Item label={'AM Class'} name={'am_class'}>
                            <Select>
                                {
                                    amClassChoicesAtom.map((choice:any) => {
                                        return <Select.Option key={choice} value={choice}>{choice}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item label={'Designer'} name={'designer'}>
                            <Input/>
                        </Form.Item>


                        <Form.Item label={'Engineer'} name={'engineer'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item label={'Manufacturing Technology'} name={'manufacturing_technology'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item label={'Material'} name={'material'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item label={'Notes'} name={'digitization_notes'}>
                            <Input.TextArea/>
                        </Form.Item>

                    <Form.Item label={'3D Model'}>
                        <Upload {...fileUploadProps}>
                            <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                </Col>

            </Row>
        </Card>
    )
}

export default DigitizationAdmin