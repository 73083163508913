// @ts-nocheck

import React, {useState } from 'react';
import { Card, Input, PageHeader, Tabs, Typography } from 'antd';
import { DownloadOutlined, TableOutlined } from '@ant-design/icons';
import OrderTable from './components/OrderTable';
import {companyOrders } from '../../store';
import {useAtomValue} from 'jotai';

const { TabPane } = Tabs;

const Orders = () => {
  const orders = useAtomValue(companyOrders);
  const [filteredData, setFilteredData] = useState(orders);

  const onSearch = (value: string) => {
    let result = [];
    result = orders.filter((data) => {
      // @ts-ignore
      const order_id = data.id;
      const order_id_string = order_id.toString();

      return (
        order_id_string.toLowerCase().search(value.toLowerCase()) !== -1 ||
        // @ts-ignore
        data.status.toLowerCase().search(value.toLowerCase()) !== -1
      );
    });
    setFilteredData(result);
  };

  const onOrderDelete = (orderId: any) => {
    // @ts-ignore
    const new_orders = filteredData.filter(
      (each_object) => each_object.id !== orderId,
    );
    setFilteredData(new_orders);
  };


  return (
    <>
      <PageHeader
        title="Orders"
        subTitle={
          <Typography.Text italic={true} type="secondary">
            {' '}
            ...send files, not parts
          </Typography.Text>
        }
      />

      <Card style={{ backgroundColor: '#1f1f1f', minHeight: '85vh' }}>
        <Tabs
          defaultActiveKey="1"
          tabBarExtraContent={
            <Input.Search
              onSearch={onSearch}
              style={{
                width: '100%',
                zIndex: 20000,
              }}
              size="middle"
              placeholder="Search Orders"
              enterButton
            />
          }
        >

          <TabPane
            tab={
              <span>
                <TableOutlined />
                Table view
              </span>
            }
            key={1}
          >
            <OrderTable filteredData={filteredData} />
          </TabPane>

          <TabPane
            disabled
            tab={
              <span>
                <DownloadOutlined />
                Archived
              </span>
            }
            key={2}
          />
        </Tabs>
      </Card>
    </>
  );
};

export default Orders;
