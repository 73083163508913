import React from 'react';
import { Card, Col, Descriptions, Image, Row, Tag } from 'antd';
import CostTable from '../CostTable';

const CostAnalysis = (props: any) => {
  const { product } = props;
  console.log(product);
  const fileData = product.digital_library_files;
  const costAnalysis = product.digital_library_files[0].cost_analysis;
  const leadTime = product.lead_time_days;
  const unitPriceUsd = product.unit_price_usd;

  const dateToString = (dateObject: string | number | Date) => {
    const date = new Date(dateObject);
    const currentDate = new Date(
        date.getTime() - date.getTimezoneOffset() * -60000,
    );
    return currentDate.toDateString();
  };

  return (
    <Card
      type={'inner'}
      style={{ backgroundColor: 'transparent' }}
      bordered={false}
      size={'small'}
    >
      <Row>
        <Col span={8}>
          <Row>
            <Col span={24}>
              <Card style={{ backgroundColor: 'transparent' }}>
                <Descriptions title="Cost Analysis" layout={'vertical'}>
                  <Descriptions.Item label="Created on">
                    {fileData[0] ? fileData[0].created_on : '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Updated on">
                    {fileData[0] ? dateToString(fileData[0].modified_on) : '-'}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Card style={{ backgroundColor: 'transparent' }}>
                <Image
                  height={250}
                  preview={false}
                  src={
                    //check if the image is available
                    fileData[0]
                      ? //if available, return the image
                        fileData[0].image_url
                      : //if not available, return the default image
                        'https://www.threekit.com/hubfs/TK20/Industries/ind_modular.svg'
                  }
                />
              </Card>
            </Col>
            <Col span={24}>
              <Card style={{ backgroundColor: 'transparent', height: '290px' }}>
                <Descriptions
                  title={'Model Details'}
                  column={2}
                  style={{ paddingTop: '1rem' }}
                >
                  <Descriptions.Item label="Area(mm2)">
                    <Tag>{fileData[0] ? fileData[0].area : 0}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Volume(mm3)">
                    <Tag>{fileData[0] ? fileData[0].volume : 0}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="x(mm)">
                    <Tag>{fileData[0] ? fileData[0].dim_x : 0}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="y(mm)">
                    <Tag>{fileData[0] ? fileData[0].dim_y : 0}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="z(mm)">
                    <Tag>{fileData[0] ? fileData[0].dim_z : 0}</Tag>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={16}>
          <CostTable
            data={costAnalysis}
            leadTime={leadTime}
            unitPriceUsd={unitPriceUsd}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default CostAnalysis;
