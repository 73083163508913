// @ts-nocheck

import React, { useEffect, useState } from 'react';
import {
  PageHeader,
  Card,
  Row,
  Col,
  Table,
  Tag,
  Button,
  Form,
  message,
  Input,
} from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import NewProductForm from './components/NewProductForm';
import {
  amClassChoices,
  digitizationChoices,
  categoryChoices,
  companyChoices,
} from '../../store';
import {useAtom, useAtomValue} from 'jotai';
import { Auth } from 'aws-amplify';

const Admin = (props: any) => {
  const [data, setData] = React.useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const companies = useAtomValue(companyChoices)
  const [categories, setCategories] = useAtom(categoryChoices);
  const [amClassChoicesAtom, setAmClassChoices] = useAtom(amClassChoices);
  const [digitizationStatusChoicesAtom, setDigitizationStatusChoices] =
    useAtom(digitizationChoices);
  const [isLoading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const onCreateProject = (values: any) => {
    form
      .validateFields()
      .then((values) => {
        const accessToken = localStorage.getItem('accessToken');
        const config = {
          url: `${process.env.REACT_APP_API_URL}/api/admin/DigitalLibraryAdmin/`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: values,
        };
        axios(config)
          .then((res) => {
            console.log(res.data);
            setVisible(false);
            form.resetFields();
            // @ts-ignore
            setFilteredData((prevArray) => [...prevArray, res.data]);
            message.success('New Project Created');
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onClickAdd = () => {
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const getProjects = async () => {
    setLoading(true)
    const accessToken = localStorage.getItem('accessToken');
    const config = {
      url: `${process.env.REACT_APP_API_URL}/api/admin/DigitalLibraryAdmin/`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios(config)
      .then((res) => {
        setData(res.data);
        setFilteredData(res.data)
        setLoading(false)
      })
      .catch((err) => {
        if (err.response.status === 401) {
          Auth.signOut();
        }
      });
  };

  const getOptions = () => {
    const accessToken = localStorage.getItem('accessToken');
    const config = {
      url: `${process.env.REACT_APP_API_URL}/api/admin/DigitalLibraryAdmin/get_options/`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios(config)
      .then((res) => {
        setCategories(res.data.categories);
        setAmClassChoices(res.data.amClass);
        setDigitizationStatusChoices(res.data.digitizationStatus);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '20%',
      render: (text: any, record: any) => (
        <Link to={`/ProductAdmin/${text}`} state={record}>
          {text}
        </Link>
      ),
    },
    {
      title: 'MCat Number',
      dataIndex: 'mcat_number',
      key: 'mcatNumber',
      render: (text: any, record: any) => record.digitization.mcat_number,
    },
    {
      title: 'Created on',
      dataIndex: 'created_on',
      key: 'created_on',
    },
    {
      title: 'Company Part ID',
      dataIndex: 'company_part_id',
      key: 'company_part_id',
    },
    {
      title: 'Part Name',
      dataIndex: 'part_name',
      key: 'part_name',
      width: '20%',
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text: any, record: any) => record.company.name,
    },
    {
      title: 'Category',
      dataIndex: 'category_name',
      key: 'category_name',
      render: (text: any, record: any) => record.category.name,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: any, record: any) => (
        <Tag color={'blue'}>{record.digitization.status}</Tag>
      ),
    },
    {
      title: 'Published',
      dataIndex: 'is_complete',
      key: 'published',
      render: (text: any, record: any) => {
        if (record.is_complete === true) {
          return <Tag color={'green'}>True</Tag>;
        } else {
          return <Tag color={'red'}>False</Tag>;
        }
      },
    },
  ];

  useEffect(() => {
    getProjects().then(() => {
      getOptions();
    });
  }, []);

  const searchProducts = (value:any) => {
    let result = []
    result = data.filter(product => {
      const productID = product.id
      const productName = product.part_name.toLowerCase()
      const companyPartId = product.company_part_id.toString();
      let mcatNumber
      if(product.digitization.mcat_number.toString()){
        mcatNumber = product.digitization.mcat_number.toString()
      }
      else{
        mcatNumber = ''
      }



      return(
          productID.search(value) !== -1 ||
              companyPartId.toLowerCase().search(value.toLowerCase()) !== -1 ||
              mcatNumber.toLowerCase().search(value.toLowerCase()) !== -1 ||
                productName.toLowerCase().search(value.toLowerCase()) !== -1


      )
    })
    setFilteredData(result)


  }

  return (
    <>
      <PageHeader title="Admin" subTitle="Digital Library Products" />
      <Card
        style={{ minHeight: '85vh', backgroundColor: '#1f1f1f' }}
        size={'small'}
        extra = {[
          <Input.Search onSearch={searchProducts} placeholder="Search by..." enterButton style={{width: 300, paddingRight: '20px'}}/>,
          <Button type={'primary'} onClick={onClickAdd} icon={<PlusOutlined/>}>
            Product
          </Button>
        ]}
        loading={isLoading}
      >
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={filteredData}
              pagination={{ pageSize: 10 }}
              size={'large'}
              bordered={true}
              style={{ backgroundColor: '#1f1f1f' }}
            />
            <NewProductForm
              visible={visible}
              onCancel={onCancel}
              form={form}
              onCreateProject={onCreateProject}
              companies={companies}
              categories={categories}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Admin;
