import {Col, Divider, Row, Table, Typography} from "antd";
import React from "react";


const PreviewTable = (props: any) => {
    const {tableColumns, analysisData, isLoading} = props

    return(
        <Row>
            <Col span={24}>
                <Table
                    title={() => (
                        <Row>
                            <Col span={24}>
                                <Typography.Text type={'secondary'}>
                                    Total Rows:{' '}
                                </Typography.Text>
                                {analysisData.total_rows}
                                <Divider type="vertical"/>
                                <Typography.Text type={'secondary'}>
                                    File Size:{' '}
                                </Typography.Text>
                                {analysisData.file_size}
                            </Col>
                        </Row>
                    )}
                    size={'small'}
                    columns={tableColumns}
                    dataSource={analysisData.preview_data}
                    scroll={{x: 1500, y: 600}}
                    loading={isLoading}
                    bordered={true}
                    pagination={false}
                />
            </Col>
        </Row>
    )
}

export default PreviewTable

